import Sprite from "./Sprite";

export interface DoorKit {
  open(): Sprite;

  closed(): Sprite;

  top_open(): Sprite;

  top_closed(): Sprite;
}

/**
 * Doors taken from the Ellence spritekit.
 */
export class EllenceWoodDoor implements DoorKit {
  _sheet = '/sprites/ellence.png';

  closed(): Sprite {
    return new Sprite(this._sheet,32, 447,  32, 64, true, true);
  }

  open(): Sprite {
    return new Sprite(this._sheet,32, 447,  32, 64, true, true);
  }

  top_closed(): Sprite {
    return new Sprite(this._sheet,64, 480, 32, 32, true, true);
  }

  top_open(): Sprite {
    return new Sprite(this._sheet,64, 480, 32, 32, false, true);
  }
}

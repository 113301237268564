import React from 'react';
import {observer} from "mobx-react";

// From https://thenounproject.com/term/person/3325090/
const PersonIcon = observer(() => {
  return <svg height='100%' viewBox="0 0 64 64" x="0px" y="0px">
    <title>Online Users</title>
    <g><path d="M32,30.21A12.92,12.92,0,1,0,19.09,17.29,12.93,12.93,0,0,0,32,30.21ZM32,6.38A10.92,10.92,0,1,1,21.09,17.29,10.92,10.92,0,0,1,32,6.38Zm22,45.1-2.32,7.44a1,1,0,0,1-1,.7,1,1,0,0,1-.3,0,1,1,0,0,1-.65-1.26l2.32-7.44c1-3.24.73-6-.9-8.25-2.82-3.86-9.63-6-19.18-6s-16.36,2.13-19.18,6c-1.63,2.24-1.94,5-.9,8.25l2.32,7.44a1,1,0,0,1-.65,1.26,1,1,0,0,1-.3,0,1,1,0,0,1-1-.7L10,51.48c-1.22-3.82-.81-7.29,1.19-10C14.46,37,21.65,34.64,32,34.64S49.54,37,52.79,41.45C54.8,44.19,55.21,47.66,54,51.48Z"/></g>
  </svg>;
});

export default PersonIcon;

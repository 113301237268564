import React from 'react';
import './VideoList.scss';
import Character from "../../core/Character";
import {MapLocation} from "../../maps/OfficeMap";
import MeetingIcon from "../../icons/MeetingIcon";
import VideoPane from "../VideoPane/VideoPane";
import {FADE_END_DISTANCE, FADE_START_DISTANCE} from "../../env";
import {observer} from "mobx-react";
import {ViewMode} from "../../core/Office";

export type ConferenceBladeProps = {
  me: Character,
  characters: Array<Character>,
  neighbors: (origin: MapLocation, destinations: Array<MapLocation>, maxDistance: number) => Array<number | undefined>,
  setView: (view: ViewMode) => void,
}

/**
 * The list of videos of the characters that are in range. This contains the various {@link VideoPane} objects
 * for each of the visible characters, and handles the wrapping of these videos as the page changes sizes.
 */
const VideoList = observer((props: ConferenceBladeProps) => {
  const {characters, me, neighbors, setView} = props;

  // Compute distances
  const characterLocations = characters.map(x => x.location);
  const distanceToCharacter: Array<number | undefined>
    = neighbors(me.location, characterLocations, FADE_END_DISTANCE);

  // Create the video panes
  const videoPanes = characters
    .sort((a: Character, b: Character) => {
      if (a.id === me.id) {
        return -1;
      } else if (b.id === me.id) {
        return 1;
      } else {
        return a.id.localeCompare(b.id);
      }
    }).map((c: Character, characterIndex: number) => {
      // Compute visibility
      const distance: number | undefined = distanceToCharacter[characterIndex];
      let strength;
      if (distance == null) {
        strength = 0.0;
      } else if (distance < FADE_START_DISTANCE) {
        strength = 1.0;
      } else {
        strength = 1.0 - ((distance - FADE_START_DISTANCE) / (FADE_END_DISTANCE - FADE_START_DISTANCE));
      }
      if (strength > 0) {
        return <VideoPane
          key={c.id}
          character={c}
          isMe={c.id === me.id}
          strength={strength}
          style={{
            width: 170,
            height: 128,
            margin: 8,
            borderRadius: 8,
          }}
        />;
      } else {
        return null;
      }
    }).filter(x => x != null);

  // Create the meeting button
  const meetingIcon = <div
    className="MeetingModeButton"
    onClick={() => setView('meeting')}
  >
    <div className="IconContainer">
      <MeetingIcon/>
    </div>
    <div className="IconText">
      Go to Meeting View
    </div>
  </div>;

  return <div
    className="ConferenceBlade"
    style={{
      minWidth: (videoPanes.length / 2) * 186,
    }}
  >
    {videoPanes}
    {meetingIcon}
  </div>;
});

export default VideoList;

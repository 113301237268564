import Sprite from "./Sprite";

/**
 * The specification of a floor styles for the map.
 */
export interface FloorKit {

  /**
   * The sprite to use for carpeted floors.
   * This should be a 32x32px sprite.
   */
  carpet(): Sprite;

  /**
   * The sprite to use for tiled floors.
   * This should be a 32x32px sprite.
   */
  tile(): Sprite;
}

/**
 * Floors taken from the Ellence spritekit.
 */
export class EllenceFloors implements FloorKit {
  _sheet = '/sprites/ellence.png';

  carpet(): Sprite {
    return new Sprite(this._sheet, 0, 32, 32, 32, false, false);
  }

  tile(): Sprite {
    return new Sprite(this._sheet, 256, 32, 32, 32, false, false);
  }
}

import CharacterSpriteSpec from "../sprites/CharacterSpriteSpec";
import {MapLocation} from "../maps/OfficeMap";

/**
 * In-memory storage, in case LocalStorage isn't enabled
 */
const memoryStorage: Map<string, any> = new Map();

/**
 * Set a value in LocalStorage. This is stored as a JSON blob of the value, so make
 * sure that it makes sense to serialize it as JSON.
 */
function set(key: string, value: any) {
  if (typeof (Storage) !== 'undefined') {
    localStorage.setItem(key, JSON.stringify(value));
  } else {
    memoryStorage.set(key, JSON.stringify(value));
  }
}

/**
 * Get a value from LocalStorage, parsing it as a JSON blob.
 */
function get(key: string): any {
  let rawObject: string | any;
  if (typeof (Storage) !== 'undefined') {
    rawObject = localStorage.getItem(key);
  } else {
    rawObject = memoryStorage.get(key);
  }
  if (rawObject != null) {
    return JSON.parse(rawObject);
  } else {
    return null;
  }
}

/**
 * Set the name of the last room we joined. This is used to pre-populate our input
 * for the room to join.
 */
export function setLastRoomName(name: string): void {
  set('lastRoomName', name);
}

/**
 * Get the name of the last room we joined. This is used to pre-populate our input
 * for the room to join.
 */
export function getLastRoomName(): string | null {
  return get('lastRoomName');
}

/**
 * Set our character's name. This is used to populate this name the next time we refresh the page.
 */
export function setName(name: string): void {
  set('myName', name);
}

/**
 * Get our character's name. This is used to populate this name the next time we refresh the page.
 */
export function getName(): string | null {
  return get('myName');
}

/**
 * Set the metadata required to generate our character's sprite on reload.
 */
export function setCharacterSprite(sprite: CharacterSpriteSpec): void {
  set('mySprite', {gender: sprite.gender, style: sprite.style});
}

/**
 * Get our character's sprite, if any was saved in LocalStorage.
 */
export function getCharacterSprite(): CharacterSpriteSpec | null {
  const json =  get('mySprite');
  if (json == null) {
    return null;
  } else {
    try {
      return new CharacterSpriteSpec(json.gender, json.style);
    } catch (e) {
      console.error('Could not load sprite from local storage', e);
      return null;
    }
  }
}

/**
 * Set our current position on the map, so that we return to that position when we reload
 * the page.
 */
export function setPosition(location: MapLocation): void {
  set('myLocation', JSON.stringify(location));
}

/**
 * Get our current position on the map, so that we return to that position when we reload
 * the page.
 */
export function getPosition(): MapLocation | null {
  const json: string | null = get('myLocation');
  if (json) {
    return JSON.parse(json) as MapLocation;
  } else {
    return null;
  }
}

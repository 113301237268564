/**
 * An event descriptor for when a track is manually added or removed
 * to a media stream. This fires when a user manually turns their camera or audio
 * on or off.
 */
import {action, computed, observable, runInAction} from "mobx";
import {Peer} from "./PeerProvider";
import SharedValue from "./SharedValue";

/**
 * A state for storing whether a camera has audio and/or video.
 * Used primarily in {@link SharedValue}.
 */
export type CameraEnabledState = {
  haveAudio: boolean,
  haveVideo: boolean,
}

/**
 * An abstract representation of a "camera", including video + audio, and managing a lot of the state associated
 * with tracks attaching / detaching from the underlying stream.
 */
export default class Camera {
  /**
   * The underlying video stream. This should contain a single track with only video.
   * We break this out because the HTML5 video element can't elegantly turn audio on and off when there's no video.
   */
  @observable videoStream?: MediaStream;

  /**
   * The underlying audio stream. This should contain a single track with only audio.
   * We break this out because the HTML5 video element can't elegantly turn audio on and off when there's no video.
   */
  @observable audioStream?: MediaStream;

  /**
   * The state of our camera; whether we have audio and/or video, and whether
   * the remote knows about this. This is the state we aspire to be in and should work
   * towards. Note that this doesn't necessarily reflect the current state of the camera.
   *
   * This is the state that we should communicate to our peer, and this is the state that
   * should show up in the buttons. However, it's not what we should use to trigger our actual
   * video rendering
   */
  @observable aspirationalState: SharedValue<CameraEnabledState>;

  /**
   * The actual state of our camera, as best as we can determine. This is what we should use for actually
   * rendering our video. This is only relevant for local cameras.
   */
  @observable _actualState: CameraEnabledState;

  constructor(state: SharedValue<CameraEnabledState>) {
    this.aspirationalState = state;
    this._actualState = {haveVideo: false, haveAudio: false};
  }

  /**
   * Signals that, after all the asynchronous bullshit is done, we intend to have audio on this
   * camera. Importantly, this doesn't mean we actually have audio on the camera currently.
   */
  @computed get willHaveAudio(): boolean {
    return !!this.aspirationalState.localValue?.haveAudio;
  }

  /**
   * Signals that, after all the asynchronous bullshit is done, we intend to have video on this
   * camera. Importantly, this doesn't mean we actually have video on the camera currently.
   */
  @computed get willHaveVideo(): boolean {
    return !!this.aspirationalState.localValue?.haveVideo;
  }

  /**
   * Tracks whether we have an honest to God audio track that we can start playing. This is true if
   * the track exists and is playable.
   */
  @computed get haveAudio(): boolean {
    return this._actualState.haveAudio;
  }

  /**
   * Tracks whether we have an honest to God video track that we can start playing. This is true if
   * the track exists and is playable.
   */
  @computed get haveVideo(): boolean {
    return this._actualState.haveVideo;
  }

  /**
   * Stop this camera. This will stop all of the tracks of the camera.
   */
  close(peers?: Array<Peer>) {
    this._removeLocalStream('video', peers);
    this._removeLocalStream('audio', peers);
  }

  /**
   * Add a new track to our camera, along with the media stream it's associated with if that stream
   * doesn't exist.
   *
   * @param track The new track we are adding.
   * @param streamIfNeeded The media stream that track is associated with. This is used to initialize our media
   *                       stream if it doesn't exist. Otherwise, it should always match a stream that already exists.
   * @param fromRemote Signals whether this track is coming from a remote host. This is used entirely for
   *                   logging the value for debugging.
   */
  addMedia = action((track: MediaStreamTrack, streamIfNeeded: MediaStream, fromRemote: boolean = false): void => {
    console.debug('Camera: addMedia() adding track=', track.id, 'to stream=', streamIfNeeded.id, 'of type=', track.kind,
      fromRemote ? 'from remote' : 'from local change');
    if (track.kind === 'video') {

      // Case: we're adding a video track
      // Set up our stream if we need to
      if (this.videoStream?.id !== streamIfNeeded.id) {
        console.debug('Camera: addMedia() overwriting existing video stream.', this.videoStream?.id, '!==', streamIfNeeded.id);
        this.videoStream = streamIfNeeded;
        this.videoStream.onremovetrack = action((ev: MediaStreamTrackEvent) => {
          console.debug('Camera: detected removed track=', ev.track.id, 'of type=', ev.track.kind);
          this.videoStream?.removeTrack(ev.track);
          // Register that we lost media
          this._actualState.haveVideo = false;
        });
      }
      // Add our track. This requires first clearing all our tracks, or else the appropriate WebRTC
      // triggers don't fire.
      this.videoStream.getTracks().forEach(action((x: MediaStreamTrack) => {
        this._actualState.haveVideo = false;
        this.videoStream?.removeTrack(x)
      }));
      this.videoStream.addTrack(track);
      // Register that we have media
      if (track.muted) {
        track.onunmute = action(() => {
          if (!this.videoStream || this.videoStream.getTracks().findIndex(t => t.id === track.id) >= 0) {
            this._actualState.haveVideo = true;
            console.debug('Camera: track.onunmute() registered haveVideo=true');
          } else {
            console.warn('Camera: track.onunmute() can no longer find track=', track.id)
          }
        });
      } else {
        runInAction(() => {
          this._actualState.haveVideo = true;
          console.debug('Camera: addMedia() immediately registering haveVideo=true');
        })
      }

    } else if (track.kind === 'audio') {

      // Case: we're adding an audio track
      // Set up our stream if we need to
      if (this.audioStream?.id !== streamIfNeeded.id) {
        console.debug('Camera: addMedia() overwriting existing audio stream.', this.videoStream?.id, '!==', streamIfNeeded.id);
        this.audioStream = streamIfNeeded;
        this.audioStream.onremovetrack = action((ev: MediaStreamTrackEvent) => {
          console.debug('Camera: detected removed audio track=', ev.track.id);
          // Register that we lost media
          this._actualState.haveAudio = false;
        });
      }
      // Add our track. This requires first clearing all our tracks, or else the appropriate WebRTC
      // triggers don't fire.
      this.audioStream.getTracks().forEach(action((x: MediaStreamTrack) => {
        this._actualState.haveAudio = false;
        this.audioStream?.removeTrack(x)
      }));
      this.audioStream.addTrack(track);
      // Register that we have media
      if (track.muted) {
        track.onunmute = action(() => {
          this._actualState.haveAudio = true;
          if (!this.audioStream || this.audioStream.getTracks().findIndex(t => t.id === track.id) >= 0) {
            this._actualState.haveAudio = true;
            console.debug('Camera: track.onunmute() registered haveAudio=true');
          } else {
            console.warn('Camera: track.onunmute() can no longer find track=', track.id)
          }
        });
      } else {
        runInAction(() => {
          this._actualState.haveAudio = true;
          console.debug('Camera: addMedia() immediately registering haveAudio=true');
        })
      }

    } else {
      throw new Error(`Unknown track type from remote: ${track.kind}`);
    }
  });

  /**
   * Get the local user's media device and add it to this camera. This should only be used on the local camera,
   * otherwise weird things will happen.
   *
   * @param type The type of stream. Either 'audio' or 'video'.
   * @param peers The peers we know about, so that we can signal to them that this stream has been added.
   *
   * @private
   */
  _addLocalStream = async (
    type: 'audio' | 'video',
    peers: Array<Peer>,
  ): Promise<void> => {
    // Get our new stream
    const newStream = await navigator.mediaDevices.getUserMedia({video: type === 'video', audio: type === 'audio'});
    if (newStream.getTracks().length !== 1) {
      console.warn('Got more than one track from the physical camera. Taking only the first track');
    }
    const newTrack = newStream.getTracks()[0];
    console.debug('Camera: _addLocalStream() got stream=', newStream.id, 'type=', type);

    // Add our stream locally
    this.addMedia(newTrack, newStream);
    console.debug('Camera: _addLocalStream() added stream=', newStream.id, 'type=', type);

    // Signal that a stream was added
    for (const peer of peers) {
      console.debug('Camera: _addLocalStream() adding stream for peer=', peer.character.id);
      await peer.addTrack(newTrack, newStream);
    }
  };

  /**
   * Remove a local stream from the local camera. This should only be used on the local camera,
   * otherwise weird things will happen.
   *
   * @param type The type of stream. Either 'audio' or 'video'.
   * @param peers The peers we know about, so that we can signal to them that this stream has been removed.
   *
   * @private
   */
  _removeLocalStream = async (
    type: 'audio' | 'video',
    peers?: Array<Peer>,
  ): Promise<void> => {
    const stream = (type === 'video' ? this.videoStream : this.audioStream);
    stream?.getTracks().forEach(track => {
      // remove our stream locally
      track.stop();
      stream.removeTrack(track);
      runInAction(() => {
        if (type === 'video') {
          this._actualState.haveVideo = false;
        } else {
          this._actualState.haveAudio = false;
        }
        console.debug('Camera: _removeLocalStream() registered haveVideo=false');
      });

      // Signal that a stream was removed
      if (peers) {
        for (const peer of peers) {
          console.debug('Camera: _removeLocalStream() removing track=', track.id, 'for peer=', peer.character.id);
          peer.removeTrack(track);
        }
      }
    });
    console.debug('Camera: _removeLocalStream() removed local stream. type=', type);
  };

  /**
   * Enable or disable the local video on this camera. This should only called on a local camera.
   */
  enableVideo = action(async (
    enable: boolean,
    peers: Array<Peer>,
  ): Promise<void> => {
    // Use the bleeding edge value of our aspirational state
    const haveVideo: boolean = !!this.aspirationalState.localValue?.haveVideo;
    try {
      this.aspirationalState.set({haveAudio: !!this.aspirationalState.localValue?.haveAudio, haveVideo: enable});
    } catch (ignored) {}
    if (enable && !haveVideo) {
      // Case: we're adding video
      await this._addLocalStream('video', peers);
    } else if (!enable && haveVideo) {
      // Case: we're removing video
      await this._removeLocalStream('video', peers);
    }
  });

  /**
   * Enable or disable the local audio on this camera. This should only called on a local camera.
   */
  enableAudio = action(async (
    enable: boolean,
    peers: Array<Peer>,
  ): Promise<void> => {
    // Use the bleeding edge value of our aspirational state
    const haveAudio: boolean = !!this.aspirationalState.localValue?.haveAudio;
    try {
      this.aspirationalState.set({haveVideo: !!this.aspirationalState.localValue?.haveVideo, haveAudio: enable});
    } catch (ignored) {}
    if (enable && !haveAudio) {
      // Case: we're adding video
      await this._addLocalStream('audio', peers);
    } else if (!enable && haveAudio) {
      // Case: we're removing video
      await this._removeLocalStream('audio', peers);
    }
  });

}
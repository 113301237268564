import React, {ReactElement, useState} from 'react';
import CharacterSpriteSpec, {SPRITE_STYLE_COUNT} from "../../sprites/CharacterSpriteSpec";
import './CharacterSpriteSelector.scss'
import {getCharacterSprite} from "../../core/storage";
import {observer} from "mobx-react";

type CharacterSpriteSelectorProps = {
  onSpriteSelected?: (selection: CharacterSpriteSpec) => void,
  className?: string,
}

/**
 * A widget for selecting our character's sprite. It handles the styling of different sprites, and the saving
 * of our selected sprite in local storage.
 */
const CharacterSpriteSelector = observer((props: CharacterSpriteSelectorProps) => {
  const {onSpriteSelected, className} = props;
  const [selection, setSelection] = useState(getCharacterSprite());

  const spriteRow = (gender: 'male' | 'female'): Array<ReactElement> => {
    const sprites = [];
    for (let style = 0; style < SPRITE_STYLE_COUNT; ++style) {
      const sprite = new CharacterSpriteSpec(gender, style);
      const spriteDef = sprite.sprite({row: 0, col: 0}, 'down');
      const selected = selection != null && selection.gender === gender && selection.style === style;
      sprites.push(
        <div
          key={`${gender}.${style}`}
          className={`Preview Animate${gender === 'female' ? 'F' : 'M'}${style} ${selected ? 'Selected' : 'Unselected'}`}
          style={{
            background: `url(${spriteDef.sheet}) ${-spriteDef.offsetX}px ${-spriteDef.offsetY}px`,
          }}
          onClick={() => {
            setSelection(sprite);
            if (onSpriteSelected) {
              onSpriteSelected(sprite);
            }
          }}
        />
      );
    }
    return sprites;
  };

  return <div className={`CharacterSpriteSelector ${className || ''}`}>
    <div className="Row">
      {spriteRow('female')}
    </div>
    <div className="Row">
      {spriteRow('male')}
    </div>
  </div>;

});

export default CharacterSpriteSelector;
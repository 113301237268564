import {observer} from "mobx-react";
import React, {useState} from "react";
import zxcvbn from "zxcvbn";
import LockIcon from "../../icons/LockIcon";
import Office from "../../core/Office";
import Character from "../../core/Character";
import './LobbyView.scss';


/**
 * A component to ask for a password when joining a room, and then actually construct
 * the room to join.
 */
const LobbyView = observer((props: {
  office: Office,
  ensureMe: () => Character,
}) => {
  const {office, ensureMe} = props;
  const [roomPassword, setRoomPassword] = useState('');

  const passwordStrength = zxcvbn(roomPassword || '');
  let security = '#B81D13';  // red
  if (passwordStrength.score === 4) {
    security = '#008450';  // green
  } else if (passwordStrength.score >= 2) {
    security = '#EFB700';  // yellow
  }

  return <div className="LobbyView">
    Please enter a password for the office '<span>{office.name}</span>', if there is one.
    You can leave this blank if the office doesn't have a password.
    <div className="Password">
      <div className="LockIcon" style={{fill: security}}>
        <LockIcon/>
      </div>
      <form className="InputContainer">
        <input className="HiddenRoomName" value={office.name} readOnly={true} autoComplete="username"/>
        <input
          type="password"
          autoComplete="current-password"
          value={roomPassword || ''}
          onChange={(e) => setRoomPassword(e.target.value)}
          onBlur={(e) => setRoomPassword(e.target.value)}
          onPaste={(e) => setRoomPassword(e.clipboardData.getData('text'))}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              office.join(ensureMe(), roomPassword);
            }
          }}
          autoFocus
        />
      </form>
    </div>
    <div className="ButtonRow">
      <button onClick={() => {
        office.join(ensureMe(), roomPassword);
      }}>Enter</button>
    </div>
  </div>;
});

export default LobbyView;

import {MapLocation} from '../maps/OfficeMap';
import Sprite from "./Sprite";

export const SPRITE_STYLE_COUNT = 7;

export default class CharacterSpriteSpec {

  /**
   * The spritesheet that we're getting the characters from.
   */
  _sheet: string = '/sprites/human-pack/characters_32x32_spritesheet_no_bkg.png';

  /**
   * The row of the character we're using. This is a logical row, not a pixel row.
   */
  _row: number;

  gender: 'male' | 'female';

  style: number;

  constructor(gender: 'male' | 'female', style: number) {
    this.gender = gender;
    this.style = style;
    switch (style) {
      default:
      case 0:
        this._row = gender === 'male' ? 15 : 14;
        break;
      case 1:
        this._row = gender === 'male' ? 0 : 4;
        break;
      case 2:
        this._row = gender === 'male' ? 1 : 3;
        break;
      case 3:
        this._row = gender === 'male' ? 7 : 8;
        break;
      case 4:
        this._row = gender === 'male' ? 2 : 6;
        break;
      case 5:
        this._row = gender === 'male' ? 9 : 10;
        break;
      case 6:
        this._row = gender === 'male' ? 11 : 12;
        break;
    }
  }

  sprite(position: MapLocation | number, orientation: 'up' | 'right' | 'down' | 'left'): Sprite {
    const offsetY: number = this._row * 32;
    let offsetX: number;
    switch (orientation) {
      default:
      case "down":
        offsetX = ((typeof position === 'number' ? position : position.row) % 4) * 32;
        break;
      case "right":
        offsetX = (4 + (typeof position === 'number' ? position : position.col) % 4) * 32;
        break;
      case "up":
        offsetX = (8 + (typeof position === 'number' ? position : position.row) % 4) * 32;
        break;
      case "left":
        offsetX = (12 + (typeof position === 'number' ? position : position.col) % 4) * 32;
        break;
    }
    return new Sprite(this._sheet, offsetX, offsetY, 32, 32, true, false);
  }
}

import React from 'react';
import {observer} from "mobx-react";

// From https://thenounproject.com/term/lock/3329998/
const LockIcon = observer(() => {
  return <svg height='100%' viewBox="0 0 120 120" x="0px" y="0px">
    <title>Security</title>
    <g><path d="M85.71,53.57V40.71a25.71,25.71,0,1,0-51.43,0V53.57A6.45,6.45,0,0,0,27.86,60V98.57A6.45,6.45,0,0,0,34.29,105H85.71a6.45,6.45,0,0,0,6.43-6.43V60A6.45,6.45,0,0,0,85.71,53.57Zm-45-12.86a19.29,19.29,0,1,1,38.57,0V53.57H72.86V40.71a12.86,12.86,0,1,0-25.71,0V53.57H40.71Zm25.71,0V53.57H53.57V40.71a6.43,6.43,0,1,1,12.86,0ZM85.71,95.36a3.21,3.21,0,0,1-3.21,3.21h-45a3.21,3.21,0,0,1-3.21-3.21V63.21A3.21,3.21,0,0,1,37.5,60h45a3.21,3.21,0,0,1,3.21,3.21Z"/></g>
  </svg>;
});

export default LockIcon;
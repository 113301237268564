import React from 'react';
import {observer} from "mobx-react";

// From https://thenounproject.com/search/?q=&collection=66126
const AudioIcon = observer((props: {enabled: boolean}) => {
  if (props.enabled) {
    return <svg height='100%' viewBox="0 0 24 24" x="0px" y="0px">
      <title>Microphone On</title>
      <path d="M11.5,2A3.5042,3.5042,0,0,0,8,5.5v7a3.5,3.5,0,0,0,7,0v-7A3.5042,3.5042,0,0,0,11.5,2ZM14,12.5a2.5,2.5,0,0,1-5,0v-7a2.5,2.5,0,0,1,5,0Z"/><path d="M11,17.9747V20H7.5a.5.5,0,0,0,0,1h8a.5.5,0,0,0,0-1H12V17.9747A5.5061,5.5061,0,0,0,17,12.5v-3a.5.5,0,0,0-1,0v3a4.5,4.5,0,0,1-9,0v-3a.5.5,0,0,0-1,0v3A5.5061,5.5061,0,0,0,11,17.9747Z"/>
    </svg>;
  } else {
    return <svg height='100%' width='100%' viewBox="0 0 24 24" x="0px" y="0px">
      <title>Microphone Off</title>
      <path d="M4.5,21a.5.5,0,0,0,.3945-.1929l3.1422-4.04A5.4725,5.4725,0,0,0,11,17.9747V20H7.5a.5.5,0,0,0,0,1h8a.5.5,0,0,0,0-1H12V17.9747A5.5061,5.5061,0,0,0,17,12.5v-3a.5.5,0,0,0-1,0v3A4.505,4.505,0,0,1,11.5,17a4.4561,4.4561,0,0,1-2.838-1.0367l.6112-.7859A3.4664,3.4664,0,0,0,11.5,16,3.5042,3.5042,0,0,0,15,12.5V7.8144l3.8945-5.0073a.5.5,0,0,0-.789-.6142L15,6.1856V5.5a3.5,3.5,0,0,0-7,0v7a3.4772,3.4772,0,0,0,.586,1.9322l-.6292.8089A4.4546,4.4546,0,0,1,7,12.5v-3a.5.5,0,0,0-1,0v3a5.4715,5.4715,0,0,0,1.3178,3.5627l-3.2123,4.13A.5.5,0,0,0,4.5,21ZM14,12.5A2.5026,2.5026,0,0,1,11.5,15a2.4772,2.4772,0,0,1-1.6158-.6082L14,9.1Zm-5,0v-7a2.5,2.5,0,0,1,5,0V7.4713L9.2526,13.5751A2.4721,2.4721,0,0,1,9,12.5Z"/>
    </svg>;
  }

});

export default AudioIcon;

import Character from "../../core/Character";
import React, {Component, ReactElement} from "react";
import AudioIcon from "../../icons/AudioIcon";
import './VideoPane.scss';
import {observer} from "mobx-react";
import {IReactionDisposer, reaction} from "mobx";
import Spinner from "react-spinkit";

type VideoPaneProps = {
  character: Character,
  isMe: boolean,
  strength: number,
  style?: {
    width?: number,
    height?: number,
    margin?: number,
    borderRadius?: number,
  },
}

/**
 * A wrapper around an HTML 5 video element, handling all the additional effects we'd like
 * to put on the video.
 */
@observer
export default class VideoPane extends Component<VideoPaneProps> {
  /** The reference to our HTML video element */
  videoRef: HTMLVideoElement | null = null;
  /** The reference to our HTML audio element */
  audioRef: HTMLAudioElement | null = null;
  /** A handler on the update HTML video/audio reaction, so that we can dispose of it when we unmount */
  disposeReaction: IReactionDisposer | null = null;

  /** {@inheritDoc} */
  componentDidMount() {
    const {character} = this.props;
    if (!this.videoRef || !this.audioRef) {
      console.error('Should not have been able to mount VideoPane without video+audio refs');
    }
    this.disposeReaction = reaction(
      () => ({haveVideo: character.camera.haveVideo, haveAudio: character.camera.haveAudio}),
      ({haveVideo, haveAudio}) => {
        const {videoRef, audioRef} = this;
        console.debug('VideoPane: recomputing media with haveVideo=', haveVideo, 'haveAudio=', haveAudio);
        // A ton of error checks
        if (videoRef == null || audioRef == null) {
          console.error('Modifying camera without the video and audio refs bound');
          return;
        }
        if (haveVideo && (!character.camera.videoStream || character.camera.videoStream.getTracks().length === 0)) {
          console.error('Detected that we have video, but we have no video stream (or no tracks in the stream).',
            'haveVideo=', haveVideo, 'videoStream=', character.camera.videoStream?.id,
            'tracks=', character.camera.videoStream?.getTracks());
          return;
        }
        if (haveAudio && (!character.camera.audioStream || character.camera.audioStream.getTracks().length === 0)) {
          console.error('Detected that we have audio, but we have no audio stream (or no tracks in the stream).',
            'haveAudio=', haveAudio, 'audioStream=', character.camera.audioStream?.id,
            'tracks=', character.camera.audioStream?.getTracks());
          return;
        }

        // At this point, we should know that:
        // 1. We have a video and audio ref
        // 2. If we have video, we have a video stream with a nonzero number of tracks
        // 3. If we have audio, we have an audio stream with a nonzero number of tracks

        // Handle our video
        if (haveVideo && !videoRef.srcObject) {
          videoRef.srcObject = character.camera.videoStream as MediaStream;
          videoRef.oncanplay = () => {
            videoRef.play();
          }
        } else if (!haveVideo && videoRef.srcObject) {
          videoRef.srcObject = null;
        }

        // Handle our audio
        if (haveAudio && !audioRef.srcObject) {
          audioRef.srcObject = character.camera.audioStream as MediaStream;
          audioRef.oncanplay = () => {
            audioRef.play();
          }
        } else if (!haveAudio && audioRef.srcObject) {
          audioRef.srcObject = null;
        }
      }, {fireImmediately: true})
  }

  /** {@inheritDoc} */
  componentWillUnmount() {
    // Dispose of our reaction
    if (this.disposeReaction) {
      this.disposeReaction();
    }
  }

  /** {@inheritDoc} */
  render() {
    const {character, isMe, strength, style} = this.props;
    const {location, sprite, isIdle} = character;
    const spriteDef = sprite.sprite({row: location.row + location.col, col: 0}, 'down');

    // We don't have a volume property in React :(
    if (this.audioRef) {
      this.audioRef.volume = strength;
    }

    // Create our video + audio elements
    const videoElem = <video
      className={isMe ? 'Mirrored' : ''}
      ref={(ref) => {this.videoRef = ref}}
      muted={true /* we'll play audio through the audio element */}
    />;
    const audioElem = <audio
      ref={(ref) => {this.audioRef = ref}}
      muted={isMe /* so we don't hear ourselves */}
    />;

    let videoOverlay: ReactElement | null = <div className="SpinnerOverlay">
      <Spinner name="three-bounce" className="Spinner" fadeIn="none"/>
    </div>;
    if (character.camera.haveVideo) {
      videoOverlay = null;
    } else if (!character.camera.willHaveVideo) {
      videoOverlay = <div className="SpriteOverlay">
        <div
          className="PersonSprite"
          style={{
            background: `url(${spriteDef.sheet}) ${-spriteDef.offsetX}px ${-spriteDef.offsetY}px`,
            left: `calc(50% - 16px)`,
            top: `calc(50% - 16px)`,
          }}
        />
      </div>;
    }

    return <div
      className="VideoPane"
      style={{
        ...style,
        opacity: strength,
      }}
    >
      {videoElem}
      {audioElem}
      {videoOverlay}

      {character.camera.willHaveAudio
        ? null
        : <div className="MutedIndicator">
          <AudioIcon enabled={character.camera.willHaveAudio}/>
        </div>
      }
      <div className="NameRow">
        <div className={`NameTag ${isIdle ? 'NameTagAway' : 'NameTagHere'}`}>
          {character.name}
        </div>
      </div>
    </div>;

  }
}

import SpriteKit from "../sprites/SpriteKit";
import {OfficeMap} from "./OfficeMap";

/**
 * A rendering of the Conversation team's Redwood City office in the Fitzpatrick building.
 * Some liberties have been taken to make it more suitable for the RPG environment.
 *
 * @param sprites The spritekit to use to render the office.
 */
export default function fitzpatrick(sprites: SpriteKit): OfficeMap {
  const office: OfficeMap = new OfficeMap({row: 3, col: 9});
  // Rooms are constructed clockwise from the top left corner
  office
    // Big conference room
    .tl_elbow(sprites, 0, 0)
    .hwall(sprites, 0, 1, 6)
    .indoor_wallpaper(sprites, 1, 1, 6)
    .tee_down(sprites, 0, 7)
    .vwall(sprites, 1, 7, 10, false, true)
    .put(11, 7, [sprites.floors.carpet(), sprites.doors.top_open()])
    .addAudioTrigger({
      triggerLocation: {row: 11, col: 7},
      triggerDirection: 'left',
      audioUrl: '$knock',
      audioOrigin: {row: 11, col: 6},
    })
    .tr_elbow(sprites, 12, 7)
    .hwall(sprites, 12, 1, 6)
    .tee_right(sprites, 12, 0)
    .vwall(sprites, 1, 0, 11)
    .fill(sprites.floors.carpet(), 3, 1, 9, 6)
    // AI Office
    .indoor_wallpaper(sprites, 13, 1, 6)
    .vwall(sprites, 13, 7, 2, false, true)
    .put(15, 7, [sprites.floors.carpet(), sprites.doors.top_open()])
    .addAudioTrigger({
      triggerLocation: {row: 15, col: 7},
      triggerDirection: 'left',
      audioUrl: '$knock',
      audioOrigin: {row: 15, col: 6},
    })
    .tl_elbow(sprites, 16, 7)
    .vwall(sprites, 17, 7, 6)
    .tee_left(sprites, 23, 7)
    .hwall(sprites, 23, 1, 6)
    .outdoor_wall(sprites, 24, 0, 7)
    .bl_elbow(sprites, 23, 0)
    .vwall(sprites, 13, 0, 10)
    .fill(sprites.floors.carpet(), 15, 1, 8, 6)
    // Backend Office
    .hwall(sprites, 16, 8, 6)
    .indoor_wallpaper(sprites, 17, 8, 5)
    .put(17, 13, sprites.doors.open())
    .addWarpPoint({
      origin: {row: 15, col: 13},
      destination: {row: 19, col: 13},
      triggerDirection: 'down',
    })
    .addWarpPoint({
      origin: {row: 19, col: 13},
      destination: {row: 15, col: 13},
      triggerDirection: 'up',
    })
    .addAudioTrigger({
      triggerLocation: {row: 19, col: 13},
      triggerDirection: 'down',
      audioUrl: '$knock',
    })
    .tee_down(sprites, 16, 14)
    .vwall(sprites, 17, 14, 8)
    .tee_up(sprites, 25, 14)
    .hwall(sprites, 25, 8, 6)
    .bl_elbow(sprites, 25, 7)
    .vwall(sprites, 24, 7, 1)
    .fill(sprites.floors.carpet(), 19, 8, 6, 6)
    // Misc Office
    .hwall(sprites, 16, 15, 6)
    .put(17, 15, sprites.doors.open())
    .addWarpPoint({
      origin: {row: 15, col: 15},
      destination: {row: 19, col: 15},
      triggerDirection: 'down',
    })
    .addWarpPoint({
      origin: {row: 19, col: 15},
      destination: {row: 15, col: 15},
      triggerDirection: 'up',
    })
    .addAudioTrigger({
      triggerLocation: {row: 19, col: 15},
      triggerDirection: 'down',
      audioUrl: '$knock',
    })
    .indoor_wallpaper(sprites, 17, 16, 5)
    .tee_down(sprites, 16, 21)
    .vwall(sprites, 17, 21, 6)
    .tee_right(sprites, 23, 21)
    .vwall(sprites, 24, 21, 1)
    .br_elbow(sprites, 25, 21)
    .hwall(sprites, 25, 15, 6)
    .fill(sprites.floors.carpet(), 19, 15, 6, 6)
    // Frontend Office
    .tl_elbow(sprites, 12, 22)
    .hwall(sprites, 12, 23, 7)
    .indoor_wallpaper(sprites, 13, 23, 7)
    .tee_left(sprites, 12, 30)
    .vwall(sprites, 13, 30, 10)
    .br_elbow(sprites, 23, 30)
    .hwall(sprites, 23, 22, 8)
    .hwall(sprites, 16, 22, 1, false, true)
    .put(15, 22, [sprites.floors.carpet(), sprites.doors.top_open()])
    .addAudioTrigger({
      triggerLocation: {row: 15, col: 22},
      triggerDirection: 'right',
      audioUrl: '$knock',
      audioOrigin: {row: 15, col: 23},
    })
    .vwall(sprites, 13, 22, 2, false, true)
    .fill(sprites.floors.carpet(), 17, 22, 6, 1)
    .fill(sprites.floors.carpet(), 15, 23, 8, 7)
    .outdoor_wall(sprites, 24, 22, 9)
    // Common Area
    .hwall(sprites, 0, 8, 4)
    .indoor_wallpaper(sprites, 1, 8, 1)
    .put(1, 9, sprites.doors.closed())
    .indoor_wallpaper(sprites, 1, 10, 2)
    .tee_down(sprites, 0, 12)
    .hwall(sprites, 0, 13, 4)
    .tee_down(sprites, 0, 17)
    .hwall(sprites, 0, 18, 4)
    .tee_down(sprites, 0, 22)
    .hwall(sprites, 0, 23, 7)
    .indoor_wallpaper(sprites, 1, 23, 7)
    .tr_elbow(sprites, 0, 30)
    .vwall(sprites, 1, 30, 11)
    .fill(sprites.floors.carpet(), 3, 8, 13, 4)
    .fill(sprites.floors.carpet(), 10, 12, 6, 10)
    .fill(sprites.floors.carpet(), 10, 22, 2, 1)
    .fill(sprites.floors.carpet(), 3, 23, 9, 7)
    // Small Conference Room (left)
    .indoor_wallpaper(sprites, 1, 13, 4)
    .vwall(sprites, 1, 17, 6)
    .tee_up(sprites, 7, 17)
    .hwall(sprites, 7, 13, 4)
    .bl_elbow(sprites, 7, 12)
    .indoor_wallpaper(sprites, 8, 12, 4)
    .put(8, 16, sprites.doors.open())
    .addWarpPoint({
      origin: {row: 6, col: 16},
      destination: {row: 10, col: 16},
      triggerDirection: 'down',
    })
    .addWarpPoint({
      origin: {row: 10, col: 16},
      destination: {row: 6, col: 16},
      triggerDirection: 'up',
    })
    .addAudioTrigger({
      triggerLocation: {row: 6, col: 16},
      triggerDirection: 'up',
      audioUrl: '$knock',
    })
    .indoor_wallpaper(sprites, 8, 17, 1)
    .vwall(sprites, 1, 12, 6)
    .fill(sprites.floors.carpet(), 3, 13, 4, 4)
    // Small Conference Room (right)
    .indoor_wallpaper(sprites, 1, 18, 4)
    .vwall(sprites, 1, 22, 6)
    .br_elbow(sprites, 7, 22)
    .hwall(sprites, 7, 18, 4)
    .put(8, 18, sprites.doors.open())
    .addWarpPoint({
      origin: {row: 6, col: 18},
      destination: {row: 10, col: 18},
      triggerDirection: 'down',
    })
    .addWarpPoint({
      origin: {row: 10, col: 18},
      destination: {row: 6, col: 18},
      triggerDirection: 'up',
    })
    .addAudioTrigger({
      triggerLocation: {row: 6, col: 18},
      triggerDirection: 'up',
      audioUrl: '$knock',
    })
    .indoor_wallpaper(sprites, 8, 19, 4)
    .fill(sprites.floors.carpet(), 3, 18, 4, 4)
    // Last bottom wall segment
    .outdoor_wall(sprites, 26, 7, 15)
  ;

  // Furnish the conference room
  office.withOrigin(3, 1)
    .put(2, 2, sprites.furniture.conference_table_vertical())
    .put(2, 1, sprites.furniture.office_chair('right', 1))
    .put(3, 1, sprites.furniture.office_chair('right', 1))
    .put(4, 1, sprites.furniture.office_chair('right', 1))
    .put(5, 1, sprites.furniture.office_chair('right', 1))
    .put(2, 4, sprites.furniture.office_chair('left', 1))
    .put(3, 4, sprites.furniture.office_chair('left', 1))
    .put(4, 4, sprites.furniture.office_chair('left', 1))
    .put(5, 4, sprites.furniture.office_chair('left', 1))
    .put(-2, 0, sprites.furniture.small_window())
    .put(-2, 2, sprites.furniture.small_whiteboard())
    .put(-2, 5, sprites.furniture.huge_plant())
    .put(7, 0, sprites.furniture.standing_lamp(1))
    .addAudioTrigger({
      triggerLocation: {row: 6, col: 0},
      triggerDirection: 'down',
      audioUrl: '/sounds/lamp.mp3',
    })
    .addAudioTrigger({
      triggerLocation: {row: 7, col: 1},
      triggerDirection: 'left',
      audioUrl: '/sounds/lamp.mp3',
    })
    .put(8, 1, sprites.furniture.large_trashcan('right'))
    .addAudioTrigger({
      triggerLocation: {row: 8, col: 2},
      triggerDirection: 'left',
      audioUrl: '/sounds/trashcan.mp3',
    })
  ;
  // (furnish the desk)
  office.withOrigin(5, 3)
    .put(0.5, 0, sprites.furniture.beverage(0))
    .put(1.5, 0, sprites.furniture.beverage(0))
    .put(2.5, 0, sprites.furniture.beverage(1))
    .put(2, 1.5, sprites.furniture.beverage(4))
    .put(1, 1, sprites.furniture.papers())
    .put(2.5, 0.5, sprites.furniture.paper(0))
    .put(0, 1.5, sprites.furniture.snack(2))
  ;

  // Furnish the AI office
  office.withOrigin(15, 1)
    .put(0, 0, sprites.furniture.desk('right', 0))
    .put(0, 1, sprites.furniture.office_chair('left', 0))
    .put(3, 0, sprites.furniture.desk('right', 0))
    .put(3, 1, sprites.furniture.office_chair('left', 1))
    .put(6, 0, sprites.furniture.desk('right', 0))
    .put(6, 1, sprites.furniture.office_chair('left', 0))
    .put(3, 5, sprites.furniture.desk('left', 0))
    .put(3, 4, sprites.furniture.office_chair('right',0))
    .put(6, 5, sprites.furniture.desk('right', 0))
    .put(6, 4, sprites.furniture.office_chair('right', 1))
    .put(-2, 3, sprites.furniture.small_whiteboard())
    .put(2, 5, sprites.furniture.standard_plant())
    .put(-2, 0.5, sprites.furniture.large_painting(0))
    .put(7, 1, sprites.furniture.papers())
    .addAudioTrigger({
      triggerLocation: {row: 7, col: 1},
      audioUrl: '/sounds/papers.mp3',
    })
    .put(9, 2, sprites.furniture.small_window())
  ;
  // (decorate the desks)
  office.withOrigin(15, 1)
    .put(0, 0, sprites.furniture.computer('right', 1))
    .put(0, 0.5, sprites.furniture.beverage(4))
  ;
  office.withOrigin(18, 1)
    .put(0, 0, sprites.furniture.computer('right', 0))
    .put(0.5, 0, sprites.furniture.desk_plant(2))
  ;
  office.withOrigin(21, 1)
    .put(0, 0, sprites.furniture.computer('right', 2))
    .put(1, 0, sprites.furniture.pens('right'))
  ;
  office.withOrigin(18, 6)
    .put(0, 0, sprites.furniture.computer('left', 1))
    .put(0, 0, sprites.furniture.beverage(1))
  ;
  office.withOrigin(21, 6)
    .put(0, 0, sprites.furniture.computer('left', 0))
    .put(0, 0, sprites.furniture.paper(0))
  ;

  // Furnish the Backend office
  office.withOrigin(19, 8)
    .put(2, 0, sprites.furniture.desk('right', 0))
    .put(2, 1, sprites.furniture.office_chair('left', 0))
    .put(5, 1, sprites.furniture.desk('up', 0))
    .put(4, 2, sprites.furniture.office_chair('down', 0))
    .put(4, 5, sprites.furniture.desk('left', 0))
    .put(4, 4, sprites.furniture.office_chair('right', 1))
    .put(2, 5, sprites.furniture.desk('left', 0))
    .put(2, 4, sprites.furniture.office_chair('right', 0))
    .put(-2, 0, sprites.furniture.large_bookshelf())
    .put(-2, 2, sprites.furniture.huge_plant())
    .put(-2, 3, sprites.furniture.small_whiteboard())
    .put(4, 0, sprites.furniture.standing_lamp(0))
    .addAudioTrigger({
      triggerLocation: {row: 4, col: 1},
      triggerDirection: 'left',
      audioUrl: '/sounds/lamp.mp3',
    })
    .put(0, 3, sprites.furniture.office_chair('down', 0))
    .put(7, 0, sprites.furniture.small_window())
    .put(7, 4, sprites.furniture.small_window())
  ;
  office.withOrigin(21, 8)
    .put(0, 0, sprites.furniture.computer('right', 1))
    .put(0, 0.5, sprites.furniture.beverage(3))
  ;
  office.withOrigin(24, 9)
    .put(-0.5, 0, sprites.furniture.computer('up', 1))
    .put(0, 1, sprites.furniture.paper(2))
  ;
  office.withOrigin(21, 13)
    .put(0, 0, sprites.furniture.computer('left', 2))
    .put(0, 0.5, sprites.furniture.paper(2))
  ;
  office.withOrigin(23, 13)
    .put(0, 0, sprites.furniture.computer('left', 1))
  ;

  // Furnish the Misc office
  office.withOrigin(19, 15)
    .put(0, 1, sprites.furniture.desk('down', 1))
    .put(1, 1.5, sprites.furniture.office_chair('up', 0))
    .put(0, 4, sprites.furniture.desk('down', 1))
    .put(1, 4.5, sprites.furniture.office_chair('up', 0))
    .put(0, 3, sprites.furniture.standard_plant())
    .put(-2, 1.5, sprites.furniture.large_painting(1))
    .put(-2, 4.5, sprites.furniture.small_painting(0))
    .put(3, 5, sprites.furniture.small_couch('left'))
    .put(5, 5, sprites.furniture.end_table())
    .put(4.5, 5.5, sprites.furniture.flower())
    .put(5, 50, sprites.furniture.snack(1))
    .put(4, 0, sprites.furniture.standing_lamp(0))
    .addAudioTrigger({
      triggerLocation: {row: 3, col: 0},
      triggerDirection: 'down',
      audioUrl: '/sounds/lamp.mp3',
    })
    .addAudioTrigger({
      triggerLocation: {row: 4, col: 1},
      triggerDirection: 'left',
      audioUrl: '/sounds/lamp.mp3',
    })
    .put(7, 1, sprites.furniture.small_window())
    .put(7, 5, sprites.furniture.small_window())
  ;
  office.withOrigin(19, 16)
    .put(-0.5, 0.5, sprites.furniture.computer('down', 0))
  ;
  office.withOrigin(19, 19)
    .put(-0.5, 0.5, sprites.furniture.computer('down', 0))
  ;

  // Furnish the Frontend office
  office.withOrigin(15, 22)
    // Desk 1
    .put(2, 0, sprites.furniture.desk('left', 0))
    .put(3, 1, sprites.furniture.office_chair('left', 0))
    .put(2, 0.5, sprites.furniture.snack(2))
    .put(2.5, 0, sprites.furniture.computer('right', 0))
    // Desk 2
    .put(5, 0, sprites.furniture.desk('left', 0))
    .put(5, 0, sprites.furniture.computer('right', 1))
    .put(5, 1, sprites.furniture.office_chair('left', 0))
    // Desk 3
    .put(7, 2, sprites.furniture.desk('up', 0))
    .put(6, 3, sprites.furniture.office_chair('down', 1))
    .put(6.5, 2, sprites.furniture.computer('up', 1))
    .put(7, 3.5, sprites.furniture.beverage(2))
    // Desk 4
    .put(7, 5, sprites.furniture.desk('up', 0))
    .put(6.5, 6, sprites.furniture.computer('up', 1))
    .put(7, 5.5, sprites.furniture.paper(0))
    .put(6, 5, sprites.furniture.office_chair('down', 0))
    // Decorations
    .put(-2, 1, sprites.furniture.small_whiteboard())
    .put(-1, 3, sprites.furniture.small_bookshelf('front'))
    .put(-1, 5, sprites.furniture.small_bookshelf('front'))
    .put(0, 7, sprites.furniture.small_bookshelf('left'))
    .put(-1.5, 4, sprites.furniture.flower())
    .put(-1, 5, sprites.furniture.book(1))
    .put(0, 7, sprites.furniture.book(0))
    .put(1, 6, sprites.furniture.papers())
    .addAudioTrigger({
      triggerLocation: {row: 1, col: 6},
      audioUrl: '/sounds/papers.mp3',
    })
    .put(1, 7, sprites.furniture.large_plant())
    .put(3, 7, sprites.furniture.large_couch('left'))
    .put(7, 0, sprites.furniture.large_trashcan('right'))
    // Windows
    .put(9, 2, sprites.furniture.small_window())
    .put(9, 6, sprites.furniture.small_window())
  ;

  // Furnish the Kitchen
  office.withOrigin(2, 23)
    .put(-1, 0, sprites.furniture.fridge())
    .addAudioTrigger({
      triggerLocation: {row: 1, col: 0},
      triggerDirection: 'up',
      audioUrl: '/sounds/fridge.mp3',
    })
    // Counters
    .put(0, 1, sprites.furniture.counter('top_left'))
    .put(0, 2, sprites.furniture.counter('top_middle'))
    .put(0, 3, sprites.furniture.counter('top_middle'))
    .put(0, 4, sprites.furniture.counter('top_middle'))
    .put(0, 5, sprites.furniture.counter('top_right'))
    .put(0, 6, sprites.furniture.counter('tr_elbow'))
    .put(1, 6, sprites.furniture.counter('right_end'))
    // Counter furnishings
    .put(-0.5, 2, sprites.furniture.sink())
    .addAudioTrigger({
      triggerLocation: {row: 1, col: 2},
      triggerDirection: 'up',
      audioUrl: '/sounds/sink.mp3',
    })
    .put(-0.5, 4, sprites.furniture.microwave())
    .addAudioTrigger({
      triggerLocation: {row: 1, col: 4},
      triggerDirection: 'up',
      audioUrl: '/sounds/microwave.mp3',
    })
    .put(-0.5, 5, sprites.furniture.coffee_maker())
    .addAudioTrigger({
      triggerLocation: {row: 1, col: 5},
      triggerDirection: 'up',
      audioUrl: '/sounds/coffee.mp3',
    })
    .put(0, 1, sprites.furniture.desk_plant(0))
    .put(0, 6, sprites.furniture.snack(4))
    .put(1, 6, sprites.furniture.beverage(1))
    .put(1, 6, sprites.furniture.beverage(2))
    // Dining table
    .put(3, 2, sprites.furniture.table_vertical())
    .put(3, 2, sprites.furniture.beverage(3))
    .put(4, 2, sprites.furniture.beverage(1))
    .put(4.5, 2, sprites.furniture.beverage(4))
    .put(3.5, 3, sprites.furniture.beverage(0))
    // Misc
    .put(3, 6, sprites.furniture.small_trashcan())
    .put(8, 6, sprites.furniture.large_plant())
  ;

  // Furnish the Common Area
  office.withOrigin(10, 12)
    .put(-2, 1, sprites.furniture.small_whiteboard())
    .addAudioTrigger({
      triggerLocation: {row: 0, col: 1},
      triggerDirection: 'up',
      audioUrl: '/sounds/whiteboard.mp3',
    })
    .addAudioTrigger({
      triggerLocation: {row: 0, col: 2},
      triggerDirection: 'up',
      audioUrl: '/sounds/whiteboard.mp3',
    })
    .put(-2, 8, sprites.furniture.small_whiteboard())
    .addAudioTrigger({
      triggerLocation: {row: 0, col: 8},
      triggerDirection: 'up',
      audioUrl: '/sounds/whiteboard.mp3',
    })
    .addAudioTrigger({
      triggerLocation: {row: 0, col: 9},
      triggerDirection: 'up',
      audioUrl: '/sounds/whiteboard.mp3',
    })
    .put(2, 1, sprites.furniture.small_couch('right'))
    .put(1, 2, sprites.furniture.large_couch('down'))
    .put(2, 5, sprites.furniture.small_couch('left'))
    .put(3, 3, sprites.furniture.end_table())
  ;

  // Furnish the Entryway
  office.withOrigin(3, 8)
    .put(-2, 2.5, sprites.furniture.small_painting(1))
  ;

  // Furnish Small Left Conference Room
  office.withOrigin(3, 13)
    .put(-2, 0, sprites.furniture.small_painting(2))
    .put(0, 3, sprites.furniture.standard_plant())
    .put(-2, 1, sprites.furniture.small_window())
    .put(1, 0, sprites.furniture.desk('right', 1))
    .put(1.5, 0, sprites.furniture.paper(0))
    .put(1, 1, sprites.furniture.office_chair('left', 1))
    .put(3, 1, sprites.furniture.office_chair('up', 1))
    .put(0, 2, sprites.furniture.office_chair('down', 1))
  ;

  // Furnish Small Right Conference Room
  office.withOrigin(3, 18)
    .put(-1.5, 0, sprites.furniture.tiny_painting(1))
    .put(-1.5, 2, sprites.furniture.tiny_painting(0))
    .put(0, 3, sprites.furniture.standard_plant())
    .put(-2, 1, sprites.furniture.small_window())
    .put(1, 3, sprites.furniture.desk('left', 1))
    .put(1, 3, sprites.furniture.papers())
    .put(2, 0, sprites.furniture.office_chair('right', 1))
    .put(2, 2, sprites.furniture.office_chair('left', 1))
  ;

  // Return
  return office;

}

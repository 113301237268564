import React from 'react';
import {observer} from "mobx-react";

// From https://thenounproject.com/search/?q=&collection=66126
const VideoIcon = observer((props: {enabled: boolean}) => {
  if (props.enabled) {
    return <svg height='100%' viewBox="0 0 24 24" x="0px" y="0px">
      <title>Camera On</title>
      <path d="M2.5,18h12a.5.5,0,0,0,.5-.5V14.0288l6.1914,4.8643A.4966.4966,0,0,0,21.5,19a.5.5,0,0,0,.5-.5V4.5a.5.5,0,0,0-.8086-.3931L15,8.9712V5.5a.5.5,0,0,0-.5-.5H2.5a.5.5,0,0,0-.5.5v12A.5.5,0,0,0,2.5,18ZM3,6H14v4a.5.5,0,0,0,.8086.3931L21,5.5288V17.4712l-6.1914-4.8643A.4966.4966,0,0,0,14.5,12.5a.5.5,0,0,0-.5.5v4H3Z"/>
    </svg>;
  } else {
    return <svg height='100%' width='100%' viewBox="0 0 24 24" x="0px" y="0px">
      <title>Camera Off</title>
      <path d="M15,5.5a.5.5,0,0,0-.5-.5h-.311l1.7055-2.1929a.5.5,0,0,0-.789-.6142L12.9221,5H2.5a.5.5,0,0,0-.5.5v12a.5.5,0,0,0,.5.5h.311L1.1055,20.1929a.5.5,0,1,0,.789.6142L4.0779,18H14.5a.5.5,0,0,0,.5-.5V14.0288l6.1914,4.8643A.4966.4966,0,0,0,21.5,19a.5.5,0,0,0,.5-.5V4.5a.5.5,0,0,0-.8086-.3931L15,8.9712ZM3,17V6h9.1443L3.5888,17ZM21,5.5288V17.4712l-6.1914-4.8643A.4966.4966,0,0,0,14.5,12.5a.5.5,0,0,0-.5.5v4H4.8557L13.4112,6H14v4a.5.5,0,0,0,.8086.3931Z"/>
    </svg>;
  }
});

export default VideoIcon;

import {action, computed, IReactionDisposer, observable, reaction} from "mobx";
import PeerProvider, {ChatMessage, Peer, PusherSignaling} from "./PeerProvider";
import {OfficeMap} from "../maps/OfficeMap";
import Character from "./Character";
import {sha256} from "js-sha256";
import Camera from "./Camera";
import fitzpatrick from "../maps/fitzpatrick";
import SpriteKit from "../sprites/SpriteKit";

/**
 * The view we're in. This is one of:
 *   - map: The conventional map view, where we can walk around and talk to people.
 *   - meeting: A meeting view, where all of the videos are rendered large.
 *   - lobby: The password screen, asking the user to enter the room
 */
export type ViewMode = 'map' | 'meeting' | 'lobby';

/**
 * The minimal information for defining an office space.
 */
export type OfficeInit = {
  /** The room name */
  name: string,
  /**
   * The password for the room, if it has one.
   * An null password means that we should ask for a password.
   * An empty password means that we explicitly know the password is empty.
   */
  passwordPlaintext?: string,
}

/**
 * An office space, along with the associated connection state and the office map.
 * This represents all the information we need to be wandering through an office map, and is the
 * most commonly passed around global state. An app will keep a stack of offices that we've visited.
 */
export default class Office {
  /** The name of the room */
  @observable readonly name: string;

  /** The room's password, if it has one */
  @observable passwordPlaintext: string;

  /**
   * If true, we've joined this room.
   */
  @observable joined: boolean = false;

  /**
   * The transcript of chat messages we've sent + received.
   */
  @observable readonly chatTranscript: Array<ChatMessage> = [];

  /**
   * The mechanism by which we're talking to our Peers. This includes both the signaling layer, and the
   * WebRTC connection built on top of that signaling.
   */
  @observable _peerProvider?: PeerProvider

  /**
   * The disposer to the MobX reaction that checks who we should be sending media to.
   */
  _disposeAllowedMedia?: IReactionDisposer;

  /**
   * The map we're currently on. This can be empty to begin with.
   */
  @observable map: OfficeMap;

  /**
   * Our current view: whether office view or map view.
   */
  @observable view: ViewMode;

  /** Our constructor */
  constructor(officeInit: OfficeInit) {
    this.name = officeInit.name;
    if (officeInit.passwordPlaintext == null) {
      this.view = 'lobby';  // If we have no password, ask for one
      this.passwordPlaintext = '';
    } else {
      this.view = 'map';  // if we have a password, jump straight into the map
      this.passwordPlaintext = officeInit.passwordPlaintext;
    }

    // Initialize our map
    // TODO(gabor) this should be more dynamic at some point, allowing people to chose maps
    this.map = fitzpatrick(SpriteKit.DEFAULT);
  }

  /**
   * If true, this office is connected to its peers.
   */
  @computed get connected(): boolean {
    return this._peerProvider?.isReady || false;
  }

  /**
   * Set our current view; e.g., switching between meeting and map mode.
   */
  setView = action((view: ViewMode) => {
    this.view = view;
  });

  /**
   * Get the peers that are connected to this office.
   */
  @computed get peers(): Map<string, Peer> {
    return (this._peerProvider as PeerProvider).peers;
  }

  /**
   * Toggle our video feed. This is really just a light wrapper around {@link Camera.enableVideo}.
   *
   * @param camera The camera to toggle.
   */
  toggleVideo = (camera: Camera) => {
    if (camera.willHaveVideo) {
      camera.enableVideo(false, Array.from(this.peers.values()));
    } else {
      camera.enableVideo(true, Array.from(this.peers.values()));
    }
  }

  /**
   * Toggle our audio feed. This is really just a light wrapper around {@link Camera.enableAudio}.
   *
   * @param camera The camera to toggle.
   */
  toggleAudio = (camera: Camera) => {
    if (camera.willHaveAudio) {
      camera.enableAudio(false, Array.from(this.peers.values()));
    } else {
      camera.enableAudio(true, Array.from(this.peers.values()));
    }
  }

  /**
   * Send a chat message to all our peers. See {@link PeerProvider#chat}.
   *
   * @param msg The chat message to send.
   */
  chat = action((msg: string, me: Character): void => {
    const {id, name, sprite: {gender, style}} = me;
    this._peerProvider?.chat(msg);
    this.chatTranscript.push({
      type: 'chat',
      speaker: {id, name, sprite: {gender, style}},
      message: msg,
    })
  });

  /**
   * Get the set of characters represented by our current peers. This is computed entirely from the
   * peers list in {@link peers}.
   */
  @computed get peerCharacters(): Map<string, Character> {
    const rtn: Map<string, Character> = new Map();
    this._peerProvider?.peers.forEach((c: Peer, id: string) => {
      rtn.set(id, c.character);
    })
    return rtn;
  }

  /**
   * Leave this office. This closes all of our peer connection to others in the office.
   */
  leave = action(() => {
    if (!this.joined) {
      console.warn('Trying to leave a room we are not in. Must join first');
      return;
    }
    // Close our peer provider
    this._peerProvider?.close();
    // Dispose of our reaction
    if (this._disposeAllowedMedia) {
      this._disposeAllowedMedia();
    }
    // Mark ourselves as left
    this.joined = false;
  });

  /**
   * Join or re-join this office.
   * Optionally, we can specify a password to use to join the room.
   */
  join = action((me: Character, passwordPlaintext?: string) => {
    if (this.joined) {
      console.warn('Trying to double join a room. Must leave first');
      return;
    }

    // Update our password, if one was provided.
    if (passwordPlaintext !== undefined) {
      this.passwordPlaintext = passwordPlaintext;
    }

    // Log that we're joining
    console.debug('Office: Joining office', this.name, (this.passwordPlaintext === '' ? 'with password' : ' without password'));

    // Create a Pusher channel name
    // We take the first 10 characters of the SHA256 hash of the room name as a suffix for our room,
    // to ensure that with very high likelihood two people with different passwords won't end up on the same
    // Pusher channel. Since the channel name is considered "insecure", we onlyu take the first 10 characters,
    // and try to use a moderately safe hash (sha256).
    let channelSuffix: string;
    if (this.passwordPlaintext !== '') {
      channelSuffix = `${this.name}.${sha256(this.passwordPlaintext).substring(0, 10)}`;
    } else {
      channelSuffix = this.name;
    }
    // Create our peer provider, backed by Pusher.
    this._peerProvider = new PusherSignaling(channelSuffix, this.passwordPlaintext, me,
      action((msg: ChatMessage) => this.chatTranscript.push(msg)));

    // Create our reaction for whether we should be sending media
    this._disposeAllowedMedia = reaction(
      () => {
        const peers = Array.from((this._peerProvider as PeerProvider).peers.values())
          .filter(x => x.character.id !== me.id);
        const locations = peers.map(x => x.character.location);
        return {
          peers: peers,
          peerLocations: locations,
          myLocation: me.location,
        };
      },
      ({peers, peerLocations, myLocation}) => {
        const peerDistances: Array<number | undefined> = this.map.neighbors(myLocation, peerLocations);
        for (let i = 0; i < peers.length; ++i) {
          const peer: Peer = peers[i];
          peer.allowMedia(peerDistances[i] != null, me.camera);
        }
      }, {fireImmediately: true});

    // Mark ourselves as joined
    this.joined = true;

    // Change our view to the map view
    this.view = 'map';
  });
}

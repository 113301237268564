import {Channel, default as PusherClient, Members} from 'pusher-js';
import Character, {CharacterInit} from "./Character";
import WebRtcConnection from "./WebRtcConnection";
import * as sjcl from 'sjcl';
import {SjclCipherEncrypted} from "sjcl";
import {action, computed, IReactionDisposer, observable, ObservableMap, reaction} from "mobx";
import SharedValue from "./SharedValue";
import Camera, {CameraEnabledState} from "./Camera";
import {v4 as uuid} from 'uuid';
import {play} from "./sound";

/**
 * A peer we're connecting to. This holds both the connection information and the character
 * information for that peer.
 */
export class Peer {
  /**
   * The connection to this Peer. This is a wrapped connection object, holding additional
   * metadata (e.g., the remote camera).
   */
  connection: WebRtcConnection;
  /**
   * The peer's current character information
   */
  @observable character: Character;
  /**
   * The index of the next message to send
   */
  nextMessageIndex: number = 0;
  /**
   * The index up to which we've received messages
   */
  receivedUntil: number = 0;
  /**
   * A buffer for inbound messages that have arrived out of order
   */
  inboundMessageBuffer: Array<SignalingMessage> = [];

  /**
   * This is the local camera state corresponding to the remote camera state of the peer.
   * Note that this is not the true state of the local camera, but rather the view of the local camera that
   * we're exposing to our remote. The motivation for this is two-fold:
   *
   *   1. There's a many-to-one relation of remote cameras to local cameras; and
   *   2. We want to be able to stop sending our camera to certain peers -- e.g., if they wander too far away -- in
   *      order to save bandwidth.
   */
  @observable localCameraStateMirror: SharedValue<CameraEnabledState>;

  /**
   * The state of the remote camera. This is what we should be willing to accept from our peer at any given
   * time.
   */
  @observable remoteCameraState: SharedValue<CameraEnabledState>;

  /**
   * Allow media to be sent to our remote when true. Otherwise, we are "out of range" and should not
   * be sending media to the remote host.
   */
  @observable mediaAllowed: boolean = false;

  /** Create a new peer connection */
  constructor(connection: WebRtcConnection, character: Character) {
    this.character = character;
    this.connection = connection;
    this.localCameraStateMirror = new SharedValue<CameraEnabledState>(
      (value, version) => this.sendMessage({
        type: 'shared_value_set',
        key: 'camera_state',
        value: JSON.stringify(value),
        version: version,
      }),
      () => {
        throw new Error('We should never be acknowledging a state change to our local camera');
      });
    this.remoteCameraState = character.camera.aspirationalState;
  }

  /** Close our peer connection. */
  close = () => {
    this.connection.close();
  };

  /** A small helper for atomically getting and incrementing the index of the next message we should send */
  nextIndex = (): number => {
    const rtn = this.nextMessageIndex;
    this.nextMessageIndex += 1;
    return rtn;
  };

  /**
   * Send a message to a given peer.
   * This is a secure channel, and is basically a proxy for {@link WebRtcConnection.sendMessage}.
   *
   * @param message The message to send.
   * @param transient If true, we won't buffer this message if the connection hasn't been set up yet or is
   *                  temporarily down. By default, we try to ensure delivery no matter what.
   */
  sendMessage = (message: PeerMessage, transient: boolean = false): void => {
    const messageContents = JSON.stringify(message);
    if (messageContents.length < this.connection.maxMessageSize) {
      // Case: this message is short enough to be sent as a single chunk
      this.connection.sendMessage(messageContents, transient);
    } else {
      // Case: we need to split up this message into chunks
      const chunkSize = (this.connection.maxMessageSize - 1024) / 2;  // Small chunks to account for JSON overhead
      const numChunks = Math.ceil(messageContents.length / chunkSize)
      const id = uuid();
      for (let i = 0, o = 0; i < numChunks; ++i, o += chunkSize) {
        // Create our chunk
        const chunk = messageContents.substr(o, chunkSize)
        const chunkMessage = JSON.stringify({
          type: 'chunk',
          id: id,
          part: i,
          total: numChunks,
          chunk: chunk,
        } as MessageChunk);
        // A final sanity check, though we don't do much beyond reporting the error :(
        if (chunkMessage.length > this.connection.maxMessageSize) {
          console.error('Chunk is too long and may not arrive at remote; this should be a very rare corner case.');
        }
        // Send the message
        // Note that a chunked messages can never be transient to ensure they're sent atomically
        this.connection.sendMessage(chunkMessage, false);
      }
    }
  };

  /**
   * Signal to the remote peer that we'd like to send them a track.
   *
   * @param track The track we'd like to send.
   * @param stream The stream we're sending the track on, in case this is a new stream,
   * @param tries [internal] The number of times we've tried to add the track, to prevent infinite retry loops.
   */
  addTrack = async (track: MediaStreamTrack, stream: MediaStream, tries: number = 1): Promise<void> => {
    if (this.mediaAllowed) {
      try {
        await this.localCameraStateMirror.set({
          haveVideo: track.kind === 'video' ? true : !!this.localCameraStateMirror.localValue?.haveVideo,
          haveAudio: track.kind === 'audio' ? true : !!this.localCameraStateMirror.localValue?.haveAudio,
        });
        if (this.mediaAllowed) {
          this.connection.addTrack(track, stream);
          console.debug('Peer: addTrack() successfully added track=', track.id, 'of type=', track.kind);
        } else {
          console.debug('Peer: addTrack() no longer allows media; aborting.');
        }
      } catch (e) {
        if (tries < 10 && (
          (track.kind === 'video' && !this.localCameraStateMirror.value?.haveVideo)
          || (track.kind === 'audio' && !this.localCameraStateMirror.value?.haveAudio)
        )) {
          console.debug('Peer: addTrack() encountered race while setting camera state; retrying. state=',
            JSON.stringify(this.localCameraStateMirror.localValue));
          await this.addTrack(track, stream, tries + 1);
        } else {
          console.warn('Peer: addTrack() was preempted while setting camera state; aborting. tries=', tries);
        }
      }
    }
  }

  /**
   * Signal to the remote peer that we'd like to stop sending them a track.
   *
   * @param track The track we'd like to remove / stop sending.
   * @param tries [internal] The number of times we've tried to remove the track, to prevent infinite retry loops.
   */
  removeTrack = async (track: MediaStreamTrack, tries: number = 1): Promise<void> => {
    try {
      await this.localCameraStateMirror.set({
        haveVideo: track.kind === 'video' ? false : !!this.localCameraStateMirror.localValue?.haveVideo,
        haveAudio: track.kind === 'audio' ? false : !!this.localCameraStateMirror.localValue?.haveAudio,
      });
      this.connection.removeTrack(track);
      console.debug('Peer: removeTrack() successfully removed track=', track.id, 'of type=', track.kind);
    } catch (e) {
      if (tries < 10 && (
        (track.kind === 'video' && !!this.localCameraStateMirror.value?.haveVideo)
        || (track.kind === 'audio' && !!this.localCameraStateMirror.value?.haveAudio)
      )) {
        console.debug('Peer: removeTrack() encountered race while setting camera state; retrying.');
        await this.removeTrack(track, tries + 1);
      } else {
        console.warn('Peer: removeTrack() was preempted while setting camera state; aborting.');
      }
    }
  }

  /**
   * Set whether we're allowing media to be sent to our remote peer. We'd want to turn this off if,
   * e.g., they've gone too far away on the map and we don't want to keep sending them a video stream they'll
   * never see.
   *
   * @param allow True if we should send local media to the peer defined by this object.
   * @param localCamera The local camera that holds the media we'd be sending. Used to determine what we should
   *                    send when we re-enable media.
   */
  allowMedia = async (allow: boolean, localCamera: Camera): Promise<void> => {
    if (allow && !this.mediaAllowed) {

      // Case: we're enabling media
      console.debug('Peer: allowMedia() is enabling media');
      // Mark that media is enabled. This should come first
      this.mediaAllowed = true;
      // Synchronize to our local camera state, so that we send the media we're intending to send
      if (localCamera.haveAudio) {
        if (localCamera.audioStream && localCamera.audioStream.getTracks().length > 0) {
          await this.addTrack(localCamera.audioStream.getTracks()[0], localCamera.audioStream);
        } else {
          console.warn('Peer: allowMedia() thinks camera has audio, but it does not');
        }
      }
      if (localCamera.haveVideo) {
        if (localCamera.videoStream && localCamera.videoStream.getTracks().length > 0) {
          await this.addTrack(localCamera.videoStream.getTracks()[0], localCamera.videoStream);
        } else {
          console.warn('Peer: allowMedia() thinks camera has video, but it does not');
        }
      }

    } else if (!allow && this.mediaAllowed) {

      // Case: we're disabling media
      console.debug('Peer: allowMedia() is disabling media');
      // Mark that media is disabled. This should come first
      this.mediaAllowed = false;
      // Disable media on our local mirror
      await this.localCameraStateMirror.set({
        haveVideo: false,
        haveAudio: false,
      });
      // Synchronize to our local camera state, so that we send the media we're intending to send
      if (localCamera.haveVideo) {
        if (localCamera.videoStream && localCamera.videoStream.getTracks().length > 0) {
          await this.removeTrack(localCamera.videoStream.getTracks()[0]);
        } else {
          console.warn('Peer: allowMedia() thinks camera has video, but it does not');
        }
      }
      if (localCamera.haveAudio) {
        if (localCamera.audioStream && localCamera.audioStream.getTracks().length > 0) {
          await this.removeTrack(localCamera.audioStream.getTracks()[0]);
        } else {
          console.warn('Peer: allowMedia() thinks camera has audio, but it does not');
        }
      }
    }
  };
}

/**
 * The message for letting RTC peers know about each other's config.
 */
type SdpMessage = {
  type: 'sdp';
  /** The character ID of the source of the message. */
  source: string;
  /** The ordering of this message. Messages from the same source should have monotonically increasing indices */
  index: number;
  /** The character ID of the destination of the message. */
  destination: string;
  /** The actual SDP message */
  sdp: RTCSessionDescriptionInit
}

/**
 * The message for sending ICE events back and forth between peers.
 */
type IceMessage = {
  type: 'ice';
  /** The character ID of the source of the message. */
  source: string;
  /** The ordering of this message. Messages from the same source should have monotonically increasing indices */
  index: number;
  /** The character ID of the destination of the message. */
  destination: string;
  /** The actual ICE candidate to communicate */
  candidate: RTCIceCandidateInit;
}

/**
 * An encrypted message. This holds a payload either an ICE or SDP signaling message, which is encrypted by a key
 * that must be the same for both sides of the peer connection.
 */
type EncryptedMessage = {
  type: 'encrypted';
  /** The character ID of the source of the message. */
  source: string;
  /** The ordering of this message. Messages from the same source should have monotonically increasing indices */
  index: number;
  /** The character ID of the destination of the message. */
  destination: string;
  /** The encrypted payload of the message */
  payload: SjclCipherEncrypted | string,
}

/**
 * The union type for all our types of singaling messages.
 */
type SignalingMessage = SdpMessage | IceMessage | EncryptedMessage;

/**
 * A message to signify that the character represented by the source of the message has been updated.
 */
type CharacterUpdateMessage = {
  type: 'character_updated',
  /** The new character information that we should use to update our character. */
  character: CharacterInit,
}

/**
 * The message sent when we'd like to set a shared value on the remote side of the peer.
 */
type SharedValueSetMessage = {
  type: 'shared_value_set',
  /** The name (key) of the shared value we're setting */
  key: string,
  /** The JSON-serialized value of the shared value we're setting */
  value: string,
  /** The version of the value we're setting. Used to determine if consensus is reached */
  version: number,
}

/**
 * The message sent when we acknowledge that a new shared value has been received, and send
 * that acknowledgement back to the remote peer.
 */
type SharedValueAckMessage = {
  type: 'shared_value_ack',
  /** The name (key) of the shared value we're setting */
  key: string,
  /** The version of the value we're setting. Used to determine if consensus is reached */
  version: number,
}

type MessageChunk = {
  type: 'chunk',
  /** A unique ID of this message, that we can use on the client side to stitch it together */
  id: string,
  /** The index of the part of the message. This should be between 0 and {@link total}. */
  part: number,
  /**
   * The total number of messages. If we have all the message parts, we can concatenate the contents and get
   * our intended message.
   */
  total: number,
  /** The contents of the message, as a partial JSON encoded blob of the message we're trying to reconstruct */
  chunk: string,
}

/**
 * A chat message that we're sending to all of our peers.
 */
export type ChatMessage = {
  type: 'chat',
  /**
   * Some information about the speaker of the message. This information can change, and moreover characters can leave,
   * and so we save it in the message to freeze the state it was in when sent.
   */
  speaker: {id: string, name: string, sprite: {gender: 'male' | 'female', style: number}},
  /** The message we're sending, as a plain-text string */
  message: string,
};

/**
 * An audio message, telling the peer to play a particular bit of audio.
 */
export type AudioMessage = {
  type: 'audio',
  /**
   * The URL of the audio to play. This will be passed into play() in sound.ts.
   */
  url: string,
};

/**
 * The union type for all the types of peer messages we can send to our peers.
 */
export type PeerMessage = CharacterUpdateMessage | ChatMessage | SharedValueSetMessage | SharedValueAckMessage
  | SignalingMessage | MessageChunk | AudioMessage;

/**
 * A class that provides the signaling + RTC connection for a peer. The main interface that's exposed here
 * is the {@link peers()} function, which returns a mapping from peer ID to the associated peer connection.
 * The implementation of this class is largely responsible for the signaling aspect of WebRTC, with the underlying
 * RTC connection handled by {@link WebRtcConnection}.
 */
export default abstract class PeerProvider {

  /**
   * If true, our connection to the signaling server has been established, and we are ready to get our peers.
   */
  abstract get isReady(): boolean;

  /**
   * Our list of peers. This is a mapping from the peer ID (the character ID of the peer) to the peer connection,
   * including that peer's character information.
   */
  abstract get peers(): Map<string, Peer>;

  /**
   * Close this connection, and clean up any MobX state.
   */
  abstract close(): void;

  /**
   * Send a chat message to all of our peers.
   *
   * @param message The message to send.
   */
  abstract chat(message: string): void;
}

/**
 * A signaling mechanism backed by Pusher (see pusher.io). This serves as a PeerProvider.
 */
export class PusherSignaling extends PeerProvider {

  /** A marker for when our signaling mechanism is set up and ready */
  @observable _isReady = false;

  /** The promise for when our signaling mechanism is ready. This will be resolved when _isReady is true */
  readonly _initializer: Promise<boolean>;

  /** Our pusher client that we'll use for signaling */
  readonly _pusher: PusherClient;

  /** The Pusher channel to send+receive data on */
  readonly _channel: Channel;

  /** The key to use for encrypting messages. This is usually the office password. */
  readonly _encryptionKey: string | undefined;

  /** Our character. This is used to add metadata to the messages we send our peers. */
  @observable readonly _me: Character;

  /**
   * Our peer connection / connection requests.
   * This also stores our peers' character info.
   * This doesn't include our character.
   */
  @observable readonly _peers: ObservableMap<string, Peer> = new ObservableMap();

  /**
   * A callback that gets called whenever we receive a chat message from a remote peer.
   * This is *not* called on outbound messages.
   */
  readonly _onChatMessageReceived: (msg: ChatMessage) => void;

  /**
   * The handle for the reaction that updates our peers if our character has changed.
   */
  readonly _disposeCharacterUpdatedReaction: IReactionDisposer;

  /**
   * A set of incomplete message chunks we've received but haven't completed yet.
   * This is a mapping from the chunk ID, to the set of message chunks with that id
   * that we've received so far.
   */
  readonly _messageChunks: Map<string, Array<MessageChunk>> = new Map();

  /**
   * Our constructor
   *
   * @param channelSuffix The unique suffix to identify our channel.
   * @param encryptionKey The key to use for encrypting messages. This is usually the office password.
   *                      If this is the empty string, no encryption is used.
   * @param me My character. We use this to (1) initialize the office, and (2) filter inbound messages
   *           so that we're not messaging ourselves.
   * @param onChatMessageReceived A callback that's called whenever a chat message is received.
   *                              See {@link _onChatMessageReceived}.
   */
  constructor(channelSuffix: string,
              encryptionKey: string,
              me: Character,
              onChatMessageReceived: (msg: ChatMessage) => void) {
    super();
    this._me = me;
    this._encryptionKey = encryptionKey.trim() !== '' ? encryptionKey : undefined;

    // Register our listener
    this._onChatMessageReceived = onChatMessageReceived;

    // Create our Pusher instance
    this._pusher = new PusherClient('98550ebd16f51fbb1071', {
      cluster: 'us3',
      authEndpoint: 'https://us-central1-gabor-168801.cloudfunctions.net/online-office-auth',
      auth: {
        params: {
          character: JSON.stringify(me.serialize())
        }
      }
    });

    // Subscribe to our channel
    this._channel = this._pusher.subscribe(`presence-${channelSuffix}`);

    // Handle channel membership changes
    this._initializer = new Promise<boolean>( (resolve) => {
      // Connect to the channel
      this._channel.bind("pusher:subscription_succeeded", (members: Members) => {
        members.each((member: {info: CharacterInit}) => {
          if (member.info.id !== this._me.id) {
            const existingCharacter = this._peers.get(member.info.id)?.character;
            if (existingCharacter != null) {
              existingCharacter.updateFrom(member.info);
            } else {
              this._registerPeer(member.info, false /* person already in the office is host */);
            }
          }
        });
        // Resolve our promise
        resolve();
      });
    }).then(action(() => this._isReady = true));

    // Subscribe to membership changes
    this._channel.bind("pusher:member_added", (member: {info: CharacterInit}) => {
      // Add the character, and create a connection for it
      const existingCharacter = this._peers.get(member.info.id)?.character;
      if (existingCharacter != null) {
        existingCharacter.updateFrom(member.info);
      } else {
        this._registerPeer(member.info, true);
      }
    });
    this._channel.bind("pusher:member_removed", (member: {info: CharacterInit}) => {
      const characterId = member.info.id;
      // Clean up our connection
      this._peers.get(characterId)?.close();
      this._peers.delete(characterId);
    });

    // Subscribe to signaling channel
    this._channel.bind("client-rtc-signaling", this._receiveSignalingMessage);

    // Notify our peers when anything about our character changes, or when a new peer appears
    this._disposeCharacterUpdatedReaction = reaction(
      () => ({me: this._me.serialize(), numPeers: this.peers.size}),
      ({me}) => {
        // Signal all of our peers
        this.peers.forEach((peer: Peer) => {
          peer.sendMessage({
            type: 'character_updated',
            character: me,
          } as PeerMessage, false);
        });
      }
    );
  }

  /** {@inheritDoc} */
  @computed get peers(): Map<string, Peer> {
    return this._peers;
  }

  /** {@inheritDoc} */
  @computed get isReady(): boolean {
    return this._isReady;
  }

  /** {@inheritDoc} */
  @action close(): void {
    // Stop receiving updates
    this._pusher.unsubscribe(this._channel.name);
    // Close our peers
    this._peers.forEach((peer: Peer) => {
      peer.close();
    });
    // Dispose of the listener that informs peers of character updates
    this._disposeCharacterUpdatedReaction();
  }

  /** {@inheritDoc} */
  chat(message: string): void {
    // Signal all of our peers
    const toSend: PeerMessage = {
      type: 'chat',
      speaker: {
        id: this._me.id,
        name: this._me.name,
        sprite: {
          gender: this._me.sprite.gender,
          style: this._me.sprite.style,
        },
      },
      message: message,
    };
    this._peers.forEach((peer: Peer) => {
      peer.sendMessage(toSend);
    });
  }

  /**
   * Send a message on our signaling channel.
   * This is an inherently insecure channel, and anyone snooping this message will be allowed
   * to establish a peer connection with us.
   * If we have a office password, this message will be encrypted with the password.
   *
   * @param message The message to send. This should either be an SDP or ICE message.
   *
   * @private
   */
  _sendSignalingMessage = (message: SdpMessage | IceMessage): void => {
    let payload: SignalingMessage = message;
    if (this._encryptionKey != null) {
      payload = {
        type: 'encrypted',
        source: message.source,
        destination: message.destination,
        index: message.index,
        payload: sjcl.encrypt(this._encryptionKey, JSON.stringify(message)),
      } as EncryptedMessage;
    }

    // Try to send it on the peer channel
    const peer = this.peers.get(message.destination);
    if (peer && peer.connection.channelOpen) {
      console.debug('PusherSignaling: _sendSignalingMessage() sending over peer connection');
      peer.sendMessage(payload);
    } else {
      // Otherwise, send it via Pusher
      console.debug('PusherSignaling: _sendSignalingMessage() sending via Pusher');
      if (!this._channel.trigger('client-rtc-signaling', payload)) {
        console.error('Failed to send event over Pusher! event=', message);
      }
    }
  }

  /**
   * The callback for when we receive a message on the signaling channel.
   *
   * @param newMessage The message we received. By assumption, this is a SignalingMessage
   *
   * @private
   */
  _receiveSignalingMessage = async (newMessage: SignalingMessage): Promise<void> => {
    // Don't allow receiving messages that aren't encrypted, if we have a office password.
    console.debug('PusherSignaling: received signaling message=', newMessage);
    if (this._encryptionKey != null && newMessage.type !== 'encrypted') {
      console.error('Received unencrypted signaling message in an encrypted room!');
      return;
    }

    const callback = async (msg: SignalingMessage, peer: Peer): Promise<void> => {
      console.debug('PusherSignaling: processing signaling message=', newMessage);
      switch (msg.type) {
        case 'encrypted':
          const decrypted: SignalingMessage = JSON.parse(sjcl.decrypt(this._encryptionKey || '', msg.payload));
          await callback(decrypted, peer);
          break;
        case 'sdp':
          switch (msg.sdp.type) {
            case 'offer':
              await peer.connection.onOfferReceived(msg.sdp);
              break;
            case 'answer':
              await peer.connection.onAnswerReceived(msg.sdp);
              break;
            default:
            case 'pranswer':
            case 'rollback':
              console.error('Got unexpected SDP message type', msg);
              break;
          }
          break;
        case 'ice':
          peer.connection.onIceCandidateReceived(msg.candidate);
          break;
        default:
          console.error('Got unknown signal:', msg);
          break;
      }
    }

    if (newMessage.destination === this._me.id) {
      const peer: Peer | undefined = this._peers.get(newMessage.source);
      if (peer != null) {
        // Queue the message
        peer.inboundMessageBuffer.push(newMessage);
        peer.inboundMessageBuffer.sort((a: SignalingMessage, b: SignalingMessage) => a.index - b.index);
        // Warn on out-of-order messages
        if (peer.inboundMessageBuffer.length > 0 && peer.inboundMessageBuffer[0].index > peer.receivedUntil + 1) {
          console.error('Received out of order signaling message. receivedUntil=', peer.receivedUntil,
            'nextMessageIndex=', peer.inboundMessageBuffer[0].index, 'my index=', newMessage.index);
        }
        // Poll from the queue while we can
        while (peer.inboundMessageBuffer.length > 0 && peer.inboundMessageBuffer[0].index <= peer.receivedUntil + 1) {
          const msg = peer.inboundMessageBuffer.shift() as SdpMessage;
          peer.receivedUntil = msg.index;
          await callback(msg, peer);
        }
      }
    }
  }

  /**
   * Receive a message from a peer, over the RTC data channel.
   * This is a secure channel.
   *
   * @param source The ID of the character we got the message from.
   * @param message The received message.
   *
   * @private
   */
  _receivePeerMessage = (source: string, message: string): void => {
    console.debug(`PeerProvider: _receivePeerMessage() got message "${message}"`)
    const received: PeerMessage = JSON.parse(message);
    const peer: Peer | undefined = this.peers.get(source);
    switch (received.type) {
      case 'character_updated':
        const character: Character | undefined = peer?.character;
        if (character != null && received.character != null) {
          character.updateFrom(received.character);
        }
        break;
      case 'chat':
        this._onChatMessageReceived(received);
        break;
      case 'audio':
        play(received.url);
        break;
      case 'shared_value_set':
        console.debug('PusherSignaling: _receivePeerMessage() setting key=',
          received.key, 'to value=', received.value, 'at version=', received.version)
        switch (received.key) {
          case 'camera_state':
            peer?.remoteCameraState.receiveValue(JSON.parse(received.value), received.version);
            break;
          default:
            throw new Error(`Unknown shared state key: ${received.key}`);
        }
        break;
      case 'shared_value_ack':
        console.debug('PusherSignaling: _receivePeerMessage() committing key=',
          received.key, 'at version=', received.version)
        switch (received.key) {
          case 'camera_state':
            peer?.localCameraStateMirror.commit(received.version);
            break;
          default:
            throw new Error(`Unknown shared state key: ${received.key}`);
        }
        break;
      case 'sdp':
      case 'ice':
      case 'encrypted':
        // We can use our peer connection for signaling too, once it's connected :)
        this._receiveSignalingMessage(received);
        break;
      case 'chunk':
        // Get our chunks so far
        let chunks = this._messageChunks.get(received.id);
        if (chunks == null) {
          chunks = [];
          this._messageChunks.set(received.id, chunks);
        }
        // Add the new chunk
        chunks.push(received);
        console.debug('RTC: Received chunk', received.id, 'part', received.part,
          'so we now have', chunks.length, 'of', received.total, 'chunks');
        // See if we're done receiving this message
        if (chunks.length === received.total) {
          // We're done receiving this message.
          // Compile the chunks
          const completeMessage: string = chunks
            .sort((a, b) => a.part - b.part)
            .map(x => x.chunk)
            .join('');
          console.debug('RTC: Completed chunked message', received.id);
          // Remove the message from our buffer
          this._messageChunks.delete(received.id);
          // Receive the message
          this._receivePeerMessage(source, completeMessage);
        }
        break;
      default:
        console.error('Got unknown message type from peer', received);
        break;
    }
  };

  /**
   * Register that a new peer has arrived. This is usually from a new user entering the chatroom over pusher.
   *
   * @param character The character that just joined. We get this initial information from Pusher; presumably this
   *                  is the character's saved information from their last session.
   * @param amHost If true, we should act as the host for this connection. Note that only one side of the
   *               connection can act as a host, even though both sides have to register each other as peers.
   *               Make sure that the logic is sound to ensure this.
   *
   * @private
   */
  _registerPeer = action((character: CharacterInit, amHost: boolean): void => {
    // Create a camera
    const cameraState = new SharedValue<CameraEnabledState>(
      () => {
        throw new Error('We should never be explicitly changing our remote camera');
      },
      (version) => this.peers.get(character.id)?.sendMessage({
        type: 'shared_value_ack',
        key: 'camera_state',
        version: version,
      }),
    );
    const remoteCamera = new Camera(cameraState);

    // Create the peer
    const peer: Peer = new Peer(new WebRtcConnection(remoteCamera,
      (desc: RTCSessionDescriptionInit) => {
        const msg: SdpMessage = {
          type: 'sdp',
          source: this._me.id,
          index: peer.nextIndex(),
          destination: character.id,
          sdp: desc,
        }
        console.debug('PusherSignaling: sending SDP source=', this._me.id, 'destination=', character.id,
          'msg=', msg);
        this._sendSignalingMessage(msg);
      },
      (ice: RTCIceCandidateInit) => {
        const msg: IceMessage = {
          type: 'ice',
          source: this._me.id,
          index: peer.nextIndex(),
          destination: character.id,
          candidate: ice,
        }
        console.debug('PusherSignaling: sending ICE source=', this._me.id, 'destination=', character.id,
          'msg=', msg);
        this._sendSignalingMessage(msg);
      },
      amHost,
      ),
      new Character(character, remoteCamera));

    // Register the peer
    this._peers.set(peer.character.id, peer);

    // Start the connection
    peer.connection.connect((msg) => this._receivePeerMessage(character.id, msg));
  });
}


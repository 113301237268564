import Sprite from "./Sprite";

export interface FurnitureKit {
  /**
   * A conference table laid out vertically, measuring 32 pixels wide
   * and 128px tall
   */
  conference_table_vertical(): Sprite;

  /**
   * A smaller table, measuring 32px wide and 96px tall.
   */
  table_vertical(): Sprite;

  /**
   * A 32x32 office chair, in various orientations
   * @param orientation
   * @param variant
   */
  office_chair(orientation: 'up' | 'down' | 'left' | 'right', variant: 0 | 1): Sprite;

  /**
   * A 32 px wide window that fits into a wall.
   */
  small_window(): Sprite;

  /**
   * A 64px wide whiteboard.
   */
  small_whiteboard(): Sprite;

  /**
   * A 128px wide whiteboard.
   */
  large_whiteboard(): Sprite;

  /**
   * A 96px tall plant; 32px wide.
   */
  huge_plant(): Sprite;

  /**
   * A 64px tall plant; 32px wide.
   */
  large_plant(): Sprite;

  /**
   * A 32px square plant, that's still meant to sit on the ground
   */
  standard_plant(): Sprite;

  /**
   * A slew of 16px square plants meant for desks and decoration.
   */
  desk_plant(variant: 0 | 1 | 2): Sprite;

  /**
   * A 16px wide 32px tall flower meant to be put on a desk.
   */
  flower(): Sprite;

  /**
   * A 16 square pixel beverage, meant to be put on a table or shelf.
   */
  beverage(variant: 0 | 1 | 2 | 3 | 4): Sprite;

  /**
   * A 32px squared set of papers, intended for a desk or table
   */
  papers(): Sprite;

  /**
   * A 16px squared single sheet of paper, intended for a desk or table
   */
  paper(variant: 0 | 1 | 2 | 3): Sprite;

  /**
   * A 16px squared snack -- candy, fruits, etc.
   * @param variant
   */
  snack(variant: 0 | 1 | 2 | 3 | 4): Sprite;

  /**
   * A 32px squared trash can, intended to be placed on the floor.
   */
  large_trashcan(orientation: 'left' | 'right' | 'down'): Sprite;

  /**
   * A 32px squared small trash can.
   */
  small_trashcan(): Sprite;

  /**
   * A 64px tall standing lamp, with a 32px base.
   */
  standing_lamp(variant: 0 | 1): Sprite;

  /**
   * A desk that's 32px deep and 64px wide. It comes in two variants, and all four orientations.
   */
  desk(orientation: 'left' | 'right' | 'up' | 'down', variant: 0 | 1): Sprite;

  /**
   * A 64px wide painting that can be hung on a wall
   */
  large_painting(variant: 0 | 1): Sprite;

  /**
   * A 32px wide painting that can be hung on a wall; still 64px tall
   */
  small_painting(variant: 0 | 1 | 2): Sprite;

  /**
   * A tiny square painting, 32px by 32px
   */
  tiny_painting(variant: 0 | 1): Sprite;

  /**
   * A 32px squared computer, in various orientations and various varieties
   */
  computer(orientation: 'left' | 'right' | 'up' | 'down', variant: 0 | 1 | 2): Sprite;

  /**
   * A 16x16 set of pens, suitable for placement on a desk.
   */
  pens(orientation: 'left' | 'right' | 'up' | 'down'): Sprite;

  /**
   * A 64px wide (96px tall) bookshelf
   */
  large_bookshelf(): Sprite;

  /**
   * A small bookshelf, either 64px wide (if front view) or 32px wide (if left view).
   * The bookshelf is 64px tall.
   */
  small_bookshelf(orientation: 'front' | 'left'): Sprite;

  /**
   * A 32px deep and 64px wide couch. Seats 2
   */
  small_couch(orientation: 'left' | 'right' | 'down'): Sprite;

  /**
   * A 32px deep and 96px wide couch. Seats 3
   */
  large_couch(orientation: 'left' | 'right' | 'down'): Sprite;

  /**
   * A 32px square end table
   */
  end_table(): Sprite;

  /**
   * A 32px squared closed book.
   */
  book(variant: 0 | 1 | 2): Sprite;

  /**
   * A 32px wide and 64px tall refrigerator
   */
  fridge(): Sprite;

  /**
   * A countertop. 32x32px
   */
  counter(orientation: 'top_left' | 'top_middle' | 'top_right' | 'tr_elbow' | 'right_end'): Sprite;

  /**
   * A 32px squared sink.
   */
  sink(): Sprite;

  /**
   * A 32px squared microwave.
   */
  microwave(): Sprite;

  /**
   * A 32px squared coffee maker.
   */
  coffee_maker(): Sprite;
}


export class EllenceFurniture implements FurnitureKit {
  _sheet = '/sprites/ellence.png';

  /** {@inheritDoc} */
  conference_table_vertical(): Sprite {
    return new Sprite(this._sheet, 96, 544, 64, 128, true, false);
  }

  table_vertical(): Sprite {
    return new Sprite(this._sheet, 96, 448, 64, 96, true, false);
  }

  office_chair(orientation: 'up' | 'down' | 'left' | 'right', variant: 0 | 1 = 0): Sprite {
    const offsetY = variant === 0 ? 384 : 416;
    let offsetX: number = 480;
    switch (orientation) {
      case "up":
        offsetX = 576;
        break;
      case "down":
        offsetX = 544;
        break;
      case "left":
        offsetX = 512;
        break;
      case "right":
        offsetX = 480;
        break;
    }
    return new Sprite(this._sheet, offsetX, offsetY, 32, 32, false, false, true);
  }

  small_window(): Sprite {
    return new Sprite(this._sheet, 64, 160, 32, 64, true, true);
  }

  large_whiteboard(): Sprite {
    return new Sprite(this._sheet, 128, 104, 128, 64, false, false);
  }

  small_whiteboard(): Sprite {
    return new Sprite(this._sheet, 64, 102, 64, 64, false, false);
  }

  flower(): Sprite {
    return Sprite.decoration(this._sheet, 298, 188, 16, 32);
  }

  desk_plant(variant: 0 | 1 | 2): Sprite {
    switch (variant) {
      case 0:
        return Sprite.decoration(this._sheet, 296, 168, 16, 16);
      case 1:
        return Sprite.decoration(this._sheet, 360, 168, 16, 16);
      case 2:
        return Sprite.decoration(this._sheet, 394, 202, 16, 16);
    }
    throw new Error("Could not find plant variant; TypeScript should have caught this");
  }

  huge_plant(): Sprite {
    return new Sprite(this._sheet, 320, 160, 32, 96, true, false);
  }

  large_plant(): Sprite {
    return new Sprite(this._sheet, 352, 192, 32, 64, true, false, true);
  }

  standard_plant(): Sprite {
    return new Sprite(this._sheet, 382, 224, 32, 32, true, false);
  }

  beverage(variant: 0 | 1 | 2 | 3 | 4): Sprite {
    const offsetX = 105 + 32*variant;
    return Sprite.decoration(this._sheet, offsetX, 233, 16, 16);
  }

  paper(variant: 0 | 1 | 2 | 3): Sprite {
    const offsetY = 264 + 32*variant;
    return Sprite.decoration(this._sheet, 297, offsetY, 16, 16);
  }

  papers(): Sprite {
    return Sprite.decoration(this._sheet, 288, 224, 32, 32);
  }

  snack(variant: 0 | 1 | 2 | 3 | 4): Sprite {
    const offsetY = 232 + 32*variant;
    return Sprite.decoration(this._sheet, 265, offsetY, 16, 16);
  }

  large_trashcan(orientation: 'left' | 'right' | 'down'): Sprite {
    let offsetX: number = 384;
    switch (orientation) {
      case "left":
        offsetX = 384;
        break;
      case "down":
        offsetX = 384+32;
        break;
      case "right":
        offsetX = 384+64;
        break;
    }
    return new Sprite(this._sheet, offsetX, 448, 32, 32, true, false);
  }

  small_trashcan(): Sprite {
    return new Sprite(this._sheet, 448, 512, 32, 32, true, false);

  }

  standing_lamp(variant: 0 | 1): Sprite {
    return new Sprite(this._sheet, 608, 384 + variant * 64, 32, 64, true, false);
  }

  desk(orientation: 'left' | 'right' | 'up' | 'down', variant: 0 | 1): Sprite {
    let offsetX = -1;
    let offsetY = -1;
    let width = 64;
    let height = 32;
    if (variant === 1 && orientation === 'up') {
      orientation = 'down'
    } else if (variant === 1 && orientation === 'down') {
      orientation = 'up'
    }
    switch (orientation) {
      case "right":
        offsetX = 256;
        offsetY = 384;
        width = 32;
        height = 64;
        break;
      case "left":
        offsetX = 288;
        offsetY = 384;
        width = 32;
        height = 64;
        break;
      case "up":
        offsetX = 320;
        offsetY = 384;
        break;
      case "down":
        offsetX = 320;
        offsetY = 416;
        break;
    }
    if (variant === 1) {
      offsetY += 64;
    }
    return new Sprite(this._sheet, offsetX, offsetY, width, height, true, false);
  }

  large_painting(variant: 0 | 1): Sprite {
    if (variant === 0) {
      return new Sprite(this._sheet, 414, 128, 64, 64);
    } else {
      return new Sprite(this._sheet, 0, 96, 64, 64);
    }
  }

  small_painting(variant: 0 | 1 | 2): Sprite {
    switch (variant) {
      case 0:
        return new Sprite(this._sheet, 480, 128, 32, 64);
      case 1:
        return new Sprite(this._sheet, 512, 128, 32, 64);
      case 2:
        return new Sprite(this._sheet, 608, 128, 32, 64);
    }
    throw new Error("Could not find painting variant; TypeScript should have caught this");
  }

  tiny_painting(variant: 0 | 1): Sprite {
    return new Sprite(this._sheet, 256, 160 + 32*variant, 32, 32, false, false, true);

  }

  computer(orientation: 'left' | 'right' | 'up' | 'down', variant: 0 | 1 | 2): Sprite {
    let anchorX = -1;
    let anchorY = -1;
    switch (variant) {
      case 0:
        anchorX = 320;
        anchorY = 256;
        break;
      case 1:
        anchorX = 320;
        anchorY = 320;
        break;
      case 2:
        anchorX = 384;
        anchorY = 320;
        break;
    }
    let offsetX = -1;
    let offsetY = -1;
    switch (orientation) {
      case "left":
        offsetX = anchorX + 32;
        offsetY = anchorY;
        break;
      case "right":
        offsetX = anchorX + 32;
        offsetY = anchorY + 32;
        break;
      case "up":
        offsetX = anchorX;
        offsetY = anchorY + 32;
        break;
      case "down":
        offsetX = anchorX;
        offsetY = anchorY;
        break;
    }
    return Sprite.decoration(this._sheet, offsetX, offsetY, 32, 32);
  }

  pens(orientation: 'left' | 'right' | 'up' | 'down'): Sprite {
    let offsetX = 394;
    let offsetY = 267;
    switch (orientation) {
      case "left":
        offsetX = offsetX + 32;
        break;
      case "right":
        offsetX = offsetX + 32;
        offsetY = offsetY + 32;
        break;
      case "up":
        offsetY = offsetY + 32;
        break;
      case "down":
        break;
    }
    return Sprite.decoration(this._sheet, offsetX, offsetY, 16, 16);
  }

  large_bookshelf(): Sprite {
    return new Sprite(this._sheet, 544, 544, 64, 96, true, false);
  }

  small_bookshelf(orientation: 'front' | 'left'): Sprite {
    switch (orientation) {
      case "front":
        return new Sprite(this._sheet, 416, 576, 64, 64, true, false,
          true);
      case "left":
        return new Sprite(this._sheet, 480, 576, 32, 64, true, false);
    }
  }

  small_couch(orientation: 'left' | 'right' | 'down'): Sprite {
    switch (orientation) {
      case "left":
        return new Sprite(this._sheet, 320, 512, 32, 64);
      case "right":
        return new Sprite(this._sheet, 288, 512, 32, 64);
      case "down":
        return new Sprite(this._sheet, 288, 576, 32, 64);
    }
  }

  large_couch(orientation: 'left' | 'right' | 'down'): Sprite {
    switch (orientation) {
      case "left":
        return new Sprite(this._sheet, 384, 512, 32, 96);
      case "right":
        return new Sprite(this._sheet, 352, 512, 32, 96);
      case "down":
        return new Sprite(this._sheet, 320, 608, 96, 32);
    }
  }

  end_table(): Sprite {
    return new Sprite(this._sheet, 416, 512, 32, 32, true, false);
  }

  book(variant: 0 | 1 | 2): Sprite {
    return Sprite.decoration(this._sheet, 544 + 32*variant, 229, 32, 32);
  }

  fridge(): Sprite {
    return new Sprite(this._sheet, 96, 256, 32, 64, true, false, true);
  }

  counter(orientation: 'top_left' | 'top_middle' | 'top_right' | 'tr_elbow' | 'right_end'): Sprite {
    switch (orientation) {
      case "top_left":
        return new Sprite(this._sheet, 32, 384, 32, 32, true, false, true);
      case "top_middle":
        return new Sprite(this._sheet, 64, 384, 32, 32, true, false, true);
      case "top_right":
        return new Sprite(this._sheet, 96, 384, 32, 32, true, false, true);
      case "tr_elbow":
        return new Sprite(this._sheet, 160, 256, 32, 32, true, false, true);
      case "right_end":
        return new Sprite(this._sheet, 160, 288, 32, 32, true, false, true);
    }
  }

  sink(): Sprite {
    return new Sprite(this._sheet, 64, 288, 32, 32, true, false, true);
  }

  microwave(): Sprite {
    return new Sprite(this._sheet, 32, 288, 32, 32, true, false, true);
  }

  coffee_maker(): Sprite {
    return new Sprite(this._sheet, 0, 288, 32, 32, true, false, true);
  }

}
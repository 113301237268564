import Sprite from "./Sprite";

/**
 * The specification of a wall style for the map.
 */
export interface WallKit {
  /**
   * The left edge of the front view of an indoor wall.
   * This should be 16px wide and 64px tall.
   */
  front_indoor_left(): Sprite;

  /**
   * The right edge of the front view of an indoor wall.
   * This should be 16px wide and 64px tall.
   */
  front_indoor_right(): Sprite;

  /**
   * The middle of a front view of an indoor wall.
   * This should be 16px wide and 64px tall.
   */
  front_indoor_middle(): Sprite;

  /**
   * The left edge of the front view of an outdoor wall.
   * This should be 16px wide and 64px tall.
   */
  front_outdoor_left(): Sprite;

  /**
   * The right edge of the front view of an outdoor wall.
   * This should be 16px wide and 64px tall.
   */
  front_outdoor_right(): Sprite;

  /**
   * The middle of a front view of an outdoor wall.
   * This should be 16px wide and 64px tall.
   */
  front_outdoor_middle(): Sprite;

  left(): Sprite;

  right(): Sprite;

  top(): Sprite;

  bottom(): Sprite;

  outer_topleft(): Sprite;

  outer_topright(): Sprite;

  outer_bottomleft(): Sprite;

  outer_bottomright(): Sprite;

  inner_bottomright(): Sprite;

  inner_bottomleft(): Sprite;

  inner_topright(): Sprite;

  inner_topleft(): Sprite;
}

/**
 * An adaptation of the ModernDay white walls. See white-walls.png
 */
export class WhiteWalls implements WallKit {
  _sheet = '/sprites/white-walls.png';

  front_indoor_left(): Sprite {
    return new Sprite(this._sheet, 0, 128, 16, 64, true, true);
  }

  front_indoor_middle(): Sprite {
    return new Sprite(this._sheet, 16, 128, 16, 64, true, true);
  }

  front_indoor_right(): Sprite {
    return new Sprite(this._sheet, 48, 128, 16, 64, true, true);
  }

  front_outdoor_left(): Sprite {
    return new Sprite(this._sheet, 0, 192, 16, 64, true, true);
  }

  front_outdoor_middle(): Sprite {
    return new Sprite(this._sheet, 16, 192, 16, 64, true, true);
  }

  front_outdoor_right(): Sprite {
    return new Sprite(this._sheet, 48, 192, 16, 64, true, true);
  }

  left(): Sprite {
    return new Sprite(this._sheet, 48, 16, 16, 16, true, true);
  }

  right(): Sprite {
    return new Sprite(this._sheet, 0, 16, 16, 16, true, true);
  }

  top(): Sprite {
    return new Sprite(this._sheet, 16, 48, 16, 16, true, true);
  }

  bottom(): Sprite {
    return new Sprite(this._sheet, 16, 0, 16, 16, true, true);
  }

  outer_topleft(): Sprite {
    return new Sprite(this._sheet, 48, 64+48, 16, 16, true, true);
  }

  outer_topright(): Sprite {
    return new Sprite(this._sheet, 0, 64+48, 16, 16, true, true);
  }

  outer_bottomleft(): Sprite {
    return new Sprite(this._sheet, 48, 64, 16, 16, true, true);
  }

  outer_bottomright(): Sprite {
    return new Sprite(this._sheet, 0, 64, 16, 16, true, true);
  }

  inner_bottomright(): Sprite {
    return new Sprite(this._sheet, 48, 48, 16, 16, true, true);
  }

  inner_bottomleft(): Sprite {
    return new Sprite(this._sheet, 0, 48, 16, 16, true, true);
  }

  inner_topright(): Sprite {
    return new Sprite(this._sheet, 48, 0, 16, 16, true, true);
  }

  inner_topleft(): Sprite {
    return new Sprite(this._sheet, 0, 0, 16, 16, true, true);
  }

}

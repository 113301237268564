import Character from "./core/Character";
import {action, computed, observable} from "mobx";
import Office, {OfficeInit} from "./core/Office";

/**
 * The global Mobx state for our app. This is mostly concerned with maintaining our character, and with
 * keeping track of the office we're in (if any).
 */
export default class AppState {

  /** Your character. This is not in the peers list */
  @observable _me?: Character;

  /**
   * The stack of offices we've visited. This may be empty in the beginning before we've chosen
   * a room. This is a stack to allow for returning to previous rooms that we've left.
   */
  @observable readonly _officeStack: Array<Office> = [];

  /**
   * Our constructor
   *
   * @param me Our character, if we know it in advance. This can be omitted if we don't have a character yet.
   */
  constructor(me?: Character) {
    this._me = me;
  }

  /**
   * Our own character.
   */
  @computed get me(): Character | undefined {
    return this._me;
  }

  /**
   * The office we're currently connected to.
   */
  @computed get currentOffice(): Office | null {
    if (this._officeStack.length === 0) {
      return null;
    } else {
      return this._officeStack[this._officeStack.length - 1];  // peek
    }
  }

  /**
   * The set of characters we know about. This includes ourselves and all connected peers
   */
  @computed get characters(): Map<string, Character> {
    const characters: Map<string, Character> = new Map();
    if (this._me != null) {
      characters.set(this._me.id, this._me);
    }
    if (this.currentOffice != null) {
      this.currentOffice.peers.forEach((peer) => {
        characters.set(peer.character.id, peer.character);
      });
    }
    return characters;
  }

  /**
   * Visit a new office, with the given initializing information.
   * This will leave the current office, and if we have a character it'll join the new office.
   *
   * @param office The office we're joining
   * @param me If we don't have a self-character yet, this is required to actually join the office.
   */
  visitOffice = action((office: OfficeInit, ensureMe: (office: Office) => Character) => {
    console.debug('AppState: Visiting office', office.name, (office.passwordPlaintext ? 'with password' : 'as lurker'));
    if (office.name === this.currentOffice?.name) {
      // We're already in the office we want to join
      return;
    }
    // Leave our current office
    this.currentOffice?.leave();
    // See if we can find the office in our history
    const inHistoryIndex = this._officeStack.findIndex((o) => o.name === office.name);
    if (inHistoryIndex >= 0) {
      // Case: we have an office to return to
      console.debug('Returning to office with name', office.name);
      const toReturnTo = this._officeStack[inHistoryIndex];
      this._officeStack.splice(inHistoryIndex, 1);
      this._officeStack.push(toReturnTo);
      if (this.currentOffice == null) {
        throw Error('Logic error; current office cannot be null since we just added an office');
      }
      if (this._me == null) {
        this._me = ensureMe(this.currentOffice);
      }
    } else {
      // Case: create a new office to join
      console.debug('Creating new office with name', office.name);
      this._officeStack.push(new Office(office));
      if (this.currentOffice == null) {
        throw Error('Logic error; current office cannot be null since we just added an office');
      }
      if (this._me == null) {
        this._me = ensureMe(this.currentOffice);
      }
    }
  });

  /**
   * Leave our current office, and either rejoin the last office we were in, or
   * presumably return to the landing page.
   */
  leaveOffice = action(() => {
    this._officeStack.pop()?.leave();
    if (this._me != null) {
      this.currentOffice?.join(this._me);
    }
  });
}

import Sprite from "../sprites/Sprite";
import SpriteKit from "../sprites/SpriteKit";
import * as PIXI from 'pixi.js';
import {FADE_END_DISTANCE} from "../env";

/**
 * The data we need to know about a tile to determine movement
 * and sound.
 */
export type Tile = {
  blocksMovement: boolean;
  blocksSound: boolean;
}

export type SpriteTile = {
  row: number;
  col: number;
  sprites: Array<Sprite>;
}

export type MapLocation = {
  row: number,
  col: number,
}

export type AudioTrigger = {
  /** The location on the map that triggers playing this audio */
  triggerLocation: MapLocation,
  /** Some triggers only fire when you're facing a certain direction. E.g., door sounds. */
  triggerDirection?: 'up' | 'down' | 'left' | 'right',
  /**
   * The URL of the audio to play. Usually, this is '/sounds/filename.mp3'.
   * This can also be a special string (usually prefixed with '$') which plays a random
   * audio of the given type. Instances include:
   *   - $knock: one of 6 random knocks
   */
  audioUrl: string,
  /**
   * If the audio origin is different from the trigger location, the audio origin
   * can be specified here. Otherwise, it's taken to be the person's location.
   */
  audioOrigin?: MapLocation,
}

/**
 * A helper interface for a map-like object that allows placing sprites.
 * Useful for re-normalizing the origin around a room, to avoid unintuitive
 * offsets when furnishing a room,
 */
interface CanPlace {
  put(row: number, col: number, sprites: Sprite | Array<Sprite>): CanPlace;
  addAudioTrigger(trigger: AudioTrigger): CanPlace;
}

/**
 * A point on the map that allows us to warp to another part of the map, or even another room.
 */
export type WarpPoint = {
  /**
   * The origin point of the warp. This should be a location on our map
   */
  origin: MapLocation,
  /**
   * The destination to warp to. This is either the name of another room, or a location on the map.
   */
  destination: string | MapLocation,
  /**
   * If this is set, we only warp if we try to move from this position in a given direction.
   * Otherwise, the warp happens when we enter the tile.
   */
  triggerDirection: 'up' | 'down' | 'left' | 'right' | null,
}

/**
 * An abstract representation of a map. The map is broken up into two grids: a 32px
 * semantic grid, and a 16px rendering grid. That is, sprites can be placed on half-increments
 * of the semantic grid.
 */
export class OfficeMap implements CanPlace {

  /**
   * The visual elements in this map. These are positioned on a 16px grid.
   */
  _sprites: Array<Array<Array<Sprite>>> = [];

  /**
   * A cache of the sprites for our map. This is to avoid recomputing our map every time we try to
   * re-render it.
   */
  _cachedSpriteTiles: Array<SpriteTile> | null = null;

  /**
   * The necessary information on each tile to be able to interact with the map.
   * These are positioned on a 32px grid
   */
  _tiles: Array<Array<Tile>> = [];

  /**
   * The total number of rows in the map, on the 32px grid.
   */
  rows = 0;

  /**
   * The total number of columns in the map, on the 32px grid.
   */
  cols = 0;

  /**
   * The initial spawn position of a player, if we can manage to spawn there.
   * If not, we'll try to spawn in a valid location nearby.
   */
  spawnPosition: MapLocation;

  /**
   * The set of spritesheets we are loading sprites from. These are the img resources we'll
   * need to pull from the server to render our map.
   */
  spritesheets: Set<string> = new Set();

  /**
   * Warp points are points on the map that allow you to jump to another position immediately.
   * This is useful for, e.g., doors where you have to jump over a couple of tiles
   */
  warpPoints: Array<WarpPoint> = [];

  /**
   * The list of audio triggers on the map.
   */
  _audioTriggers: Array<AudioTrigger> = [];


  /** Constructor */
  constructor(spawnPosition: MapLocation) {
    this.spawnPosition = spawnPosition;
  }

  /**
   * Add some sprites to the map at a given location, on the 32px grid. That means that rows and columns
   * can have fractional values, on a half-grid increment.
   *
   * @param row The row at which to insert this sprite, on the 32px grid.
   * @param col The column at which to insert this sprite, on the 32px grid.
   * @param sprites The sprite or sprites to insert at the given location.
   */
  put(row: number, col: number, sprites: Sprite | Array<Sprite>): OfficeMap {
    // Invalidate the cache
    this._cachedSpriteTiles = null;

    // Some conversions
    if (!Array.isArray(sprites)) {
      sprites = [sprites];
    }

    // Register the sprite(s)
    sprites.forEach((s) => {
      this.spritesheets.add(s.sheet);
    });

    // Set the sprite
    const row16 = Math.round(row * 2);
    let spriteRow: Array<Array<Sprite>> = this._sprites[row16];
    if (spriteRow === undefined) {
      spriteRow = [];
      this._sprites[row16] = spriteRow;
    }
    const col16 = Math.round(col * 2);
    let spriteCol: Array<Sprite> = spriteRow[col16];
    if (spriteCol === undefined) {
      spriteCol = [];
      spriteRow[col16] = spriteCol;
    }
    spriteCol.push(...sprites);

    // Update the tiles
    for (let s of sprites) {
      for (let r = Math.floor(row); r < Math.ceil(row + s.height / 32); ++r) {
        for (let c = Math.floor(col); c < Math.ceil(col + s.width / 32); ++c) {
          let tileRow = this._tiles[r];
          if (tileRow === undefined) {
            tileRow = [];
            this._tiles[r] = tileRow;
          }
          let tile: Tile = tileRow[c];
          if (tile === undefined) {
            tile = {
              blocksMovement: false,
              blocksSound: false
            };
            tileRow[c] = tile;
          }
          tile.blocksMovement = tile.blocksMovement || s.blocksMovement;
          tile.blocksSound = tile.blocksSound || s.blocksSound;
          this.rows = Math.max(this.rows, r);
          this.cols = Math.max(this.cols, c);
        }
      }
    }
    return this;
  }

  /**
   * Create a row of indoor wallpaper. This is 64px tall and 32px per cell, extending
   * |width| cells across.
   *
   * @param sprites The spritekit to use for the wallpaper.
   * @param row The row at which to start drawing the wall.
   * @param col The column at which to start drawing the wall.
   * @param width The number of cells of wall to draw.
   */
  indoor_wallpaper(sprites: SpriteKit, row: number, col: number, width: number): OfficeMap {
    this.put(row, col, sprites.walls.front_indoor_left());
    for (let c = 0.5; c < width - 0.5; c += 0.5) {
      this.put(row, col + c, sprites.walls.front_indoor_middle());
    }
    this.put(row, col + width - 0.5, sprites.walls.front_indoor_right());
    return this;
  }

  /**
   * Create a row of indoor wall. This is 64px tall and 32px per cell, extending
   * |width| cells across.
   *
   * @param sprites The spritekit to use for the wall.
   * @param row The row at which to start drawing the wall.
   * @param col The column at which to start drawing the wall.
   * @param width The number of cells of wall to draw.
   */
  outdoor_wall(sprites: SpriteKit, row: number, col: number, width: number): OfficeMap {
    this.put(row, col, sprites.walls.front_outdoor_left());
    for (let c = 0.5; c < width - 0.5; c += 0.5) {
      this.put(row, col + c, sprites.walls.front_outdoor_middle());
    }
    this.put(row, col + width - 0.5, sprites.walls.front_outdoor_right());
    return this;
  }

  /**
   * Create a horizontal wall segment. This doesn't include the wallpaper that should
   * accompany the 2 cells (64px) below this segment. Each cell of wall segment is 32px square.
   *
   * @param sprites The spritkit to use for the wall segment.
   * @param row The row at which to start drawing the wall.
   * @param col The column at which to start drawing the wall.
   * @param width The number of cells of wall to draw.
   * @param terminateStart If true, the left end of this wall is a terminal wall (i.e., doesn't
   *                       connect to another wall segment)
   * @param terminateEnd If true, the right end of this wall is a terminal wall (i.e., doesn't
   *                     connect to another wall segment)
   */
  hwall(sprites: SpriteKit, row: number, col: number, width: number,
        terminateStart: boolean = false, terminateEnd: boolean = false): OfficeMap {
    if (terminateStart) {
      this.put(row, col, sprites.walls.outer_topleft());
      this.put(row + 0.5, col, sprites.walls.outer_bottomleft());
      this.put(row, col + 0.5, sprites.walls.top());
      this.put(row + 0.5, col + 0.5, sprites.walls.bottom());
    }
    for (let c = (terminateStart ? 1 : 0); c < (terminateEnd ? width - 1 : width); ++c) {
      this.put(row, col + c, sprites.walls.top());
      this.put(row + 0.5, col + c, sprites.walls.bottom());
      this.put(row, col + c + 0.5, sprites.walls.top());
      this.put(row + 0.5, col + c + 0.5, sprites.walls.bottom());
    }
    if (terminateEnd) {
      this.put(row, col + width - 1, sprites.walls.top());
      this.put(row + 0.5, col + width - 1, sprites.walls.bottom());
      this.put(row, col + width - 0.5, sprites.walls.outer_topright());
      this.put(row + 0.5, col + width - 0.5, sprites.walls.outer_bottomright());
    }
    return this;
  }

  /**
   * Create a vertial wall segment. Each cell of wall segment is 32px square.
   *
   * @param sprites The spritkit to use for the wall segment.
   * @param row The row at which to start drawing the wall.
   * @param col The column at which to start drawing the wall.
   * @param height The number of cells of wall to draw.
   * @param terminateStart If true, the top end of this wall is a terminal wall (i.e., doesn't
   *                       connect to another wall segment)
   * @param terminateEnd If true, the bottom end of this wall is a terminal wall (i.e., doesn't
   *                     connect to another wall segment)
   */
  vwall(sprites: SpriteKit, row: number, col: number, height: number,
        terminateStart: boolean = false, terminateEnd: boolean = false): OfficeMap {
    if (terminateStart) {
      this.put(row, col, sprites.walls.outer_topleft());
      this.put(row + 0.5, col, sprites.walls.left());
      this.put(row, col + 0.5, sprites.walls.outer_topright());
      this.put(row + 0.5, col + 0.5, sprites.walls.right());
    }
    for (let r = (terminateStart ? 1 : 0); r < (terminateEnd ? height - 1 : height); ++r) {
      this.put(row + r, col, sprites.walls.left());
      this.put(row + r + 0.5, col, sprites.walls.left());
      this.put(row + r, col + 0.5, sprites.walls.right());
      this.put(row + r + 0.5, col + 0.5, sprites.walls.right());
    }
    if (terminateEnd) {
      this.put(row + height - 1, col, sprites.walls.left());
      this.put(row + height - 0.5, col, sprites.walls.outer_bottomleft());
      this.put(row + height - 1, col + 0.5, sprites.walls.right());
      this.put(row + height - 0.5, col + 0.5, sprites.walls.outer_bottomright());
    }
    return this;
  }

  /**
   * Create an elbow joint in a wall corresponding to the top left corner of a square.
   * It's open at the right and the bottom.
   */
  tl_elbow(sprites: SpriteKit, row: number, col: number): OfficeMap {
    this.put(row, col, sprites.walls.outer_topleft());
    this.put(row + 0.5, col, sprites.walls.left());
    this.put(row, col + 0.5, sprites.walls.top());
    this.put(row + 0.5, col + 0.5, sprites.walls.inner_topleft());
    return this;
  }

  /**
   * Create an elbow joint in a wall corresponding to the top right corner of a square.
   * It's open at the left and the bottom.
   */
  tr_elbow(sprites: SpriteKit, row: number, col: number): OfficeMap {
    this.put(row, col, sprites.walls.top());
    this.put(row + 0.5, col, sprites.walls.inner_topright());
    this.put(row, col + 0.5, sprites.walls.outer_topright());
    this.put(row + 0.5, col + 0.5, sprites.walls.right());
    return this;
  }

  /**
   * Create an elbow joint in a wall corresponding to the bottom left corner of a square.
   * It's open at the right and the top.
   */
  bl_elbow(sprites: SpriteKit, row: number, col: number): OfficeMap {
    this.put(row, col, sprites.walls.left());
    this.put(row + 0.5, col, sprites.walls.outer_bottomleft());
    this.put(row, col + 0.5, sprites.walls.inner_bottomleft());
    this.put(row + 0.5, col + 0.5, sprites.walls.bottom());
    return this;
  }

  /**
   * Create an elbow joint in a wall corresponding to the bottom right corner of a square.
   * It's open at the left and the top.
   */
  br_elbow(sprites: SpriteKit, row: number, col: number): OfficeMap {
    this.put(row, col, sprites.walls.inner_bottomright());
    this.put(row + 0.5, col, sprites.walls.bottom());
    this.put(row, col + 0.5, sprites.walls.right());
    this.put(row + 0.5, col + 0.5, sprites.walls.outer_bottomright());
    return this;
  }

  /**
   * Create a T joint in a wall, where it's open on the left, right, and bottom sides.
   */
  tee_down(sprites: SpriteKit, row: number, col: number): OfficeMap {
    this.put(row, col, sprites.walls.top());
    this.put(row + 0.5, col, sprites.walls.inner_topright());
    this.put(row, col + 0.5, sprites.walls.top());
    this.put(row + 0.5, col + 0.5, sprites.walls.inner_topleft());
    return this;
  }

  /**
   * Create a T joint in a wall, where it's open on the left, right, and top sides.
   */
  tee_up(sprites: SpriteKit, row: number, col: number): OfficeMap {
    this.put(row, col, sprites.walls.inner_bottomright());
    this.put(row + 0.5, col, sprites.walls.bottom());
    this.put(row, col + 0.5, sprites.walls.inner_bottomleft());
    this.put(row + 0.5, col + 0.5, sprites.walls.bottom());
    return this;
  }

  /**
   * Create a T joint in a wall, where it's open on the top, bottom, and left sides.
   */
  tee_left(sprites: SpriteKit, row: number, col: number): OfficeMap {
    this.put(row, col, sprites.walls.inner_bottomright());
    this.put(row + 0.5, col, sprites.walls.inner_topright());
    this.put(row, col + 0.5, sprites.walls.right());
    this.put(row + 0.5, col + 0.5, sprites.walls.right());
    return this;
  }

  /**
   * Create a T joint in a wall, where it's open on the top, bottom, and right sides.
   */
  tee_right(sprites: SpriteKit, row: number, col: number): OfficeMap {
    this.put(row, col, sprites.walls.left());
    this.put(row + 0.5, col, sprites.walls.left());
    this.put(row, col + 0.5, sprites.walls.inner_bottomleft());
    this.put(row + 0.5, col + 0.5, sprites.walls.inner_topleft());
    return this;
  }

  /**
   * Fill a given region with a sprite.
   *
   * @param sprite The sprite(s) to use to fill the region.
   * @param row The top left row to fill (inclusive)
   * @param col The top left column to fill (inclusive)
   * @param rows The number of rows to fill
   * @param cols The number of columns to fill
   */
  fill(sprite: Sprite | Array<Sprite>, row: number, col: number, rows: number, cols: number): OfficeMap {
    for (let r = 0; r < rows; ++r) {
      for (let c = 0; c < cols; ++c) {
        this.put(row + r, col + c, sprite)
      }
    }
    return this;
  }

  /**
   * Reset our "origin" to allow for placing things on the map relative to the new origin.
   * This is helpful for, e.g., decorating rooms.
   *
   * @param originRow The row of the new origin.
   * @param originCol The column of the new origin.
   */
  withOrigin(originRow: number, originCol: number): CanPlace {
    const parent = this;
    const rtn = {
      put(row: number, col: number, sprites: Sprite | Array<Sprite>): CanPlace {
        parent.put(row + originRow, col + originCol, sprites);
        return rtn;
      },
      addAudioTrigger(trigger: AudioTrigger): CanPlace {
        parent.addAudioTrigger({
          ...trigger,
          triggerLocation: {row: trigger.triggerLocation.row + originRow, col: trigger.triggerLocation.col + originCol},
          audioOrigin: trigger.audioOrigin == null ? undefined
            : {row: trigger.audioOrigin.row + originRow, col: trigger.audioOrigin.col + originCol},
        });
        return rtn;
      }
    }
    return rtn;
  }

  /**
   * Resolve any warping that'll take place from the move we're making.
   * See {@link warpPoints}.
   *
   * TODO(gabor) handle cross-room warping
   *
   * @param oldPosition The position we're currently in.
   * @param newPosition The position we'd like to move into.
   */
  resolveWarp(oldPosition: MapLocation, newPosition: MapLocation): MapLocation {
    for (let warp of this.warpPoints) {
      if (warp.triggerDirection != null && warp.origin.row === oldPosition.row &&
          warp.origin.col === oldPosition.col) {
        // Case: we're on a warp point currently. Check if our move triggered a warp
        let triggered: boolean = false;
        switch (warp.triggerDirection) {
          case "up":
            triggered = newPosition.row < oldPosition.row;
            break;
          case "down":
            triggered = newPosition.row > oldPosition.row;
            break;
          case "left":
            triggered = newPosition.col < oldPosition.col;
            break;
          case "right":
            triggered = newPosition.col > oldPosition.col;
            break;
        }
        if (triggered && typeof(warp.destination) !== 'string') {
          return this.resolveWarp(warp.destination, warp.destination);
        } else if (triggered) {
          // TODO(gabor) handle cross-room warping
        }
      } else if (warp.triggerDirection == null && warp.origin.row === newPosition.row &&
          warp.origin.col === newPosition.col) {
        // Case: we're moving onto a warp point. Warp immediately
        if (typeof(warp.destination) !== 'string') {
          return this.resolveWarp(warp.destination, warp.destination);
        } else {
          // TODO(gabor) handle cross-room warping
        }
      }
    }
    return newPosition;
  }

  /**
   * Add a new warp point to the map.
   */
  addWarpPoint(warp: WarpPoint): OfficeMap {
    this.warpPoints.push(warp);
    return this;
  }

  /**
   * Add a new audio trigger
   */
  addAudioTrigger(trigger: AudioTrigger): OfficeMap {
    this._audioTriggers.push(trigger);
    return this;
  }

  /**
   * Returns all the audio triggers that are active for a character that's in the given location
   * and orientation.
   */
  audioTriggers(location: MapLocation, orientation: 'up' | 'down' | 'left' | 'right'): Array<AudioTrigger> {
    return this._audioTriggers.filter( candidate =>
      candidate.triggerLocation.row === location.row && candidate.triggerLocation.col === location.col &&
        (candidate.triggerDirection == null || candidate.triggerDirection === orientation)
    );
  }

  /**
   * Perform an action for each sprite tile on the map. This is primarily used to render
   * all the sprites.
   */
  forEachSpriteCell(callback: (d: SpriteTile) => void): void {
    if (!this._cachedSpriteTiles) {
      this._cachedSpriteTiles = [];
      for (let renderDecoration of [false, true]) {
        for (let r = this._sprites.length - 1; r >= 0; --r) {
          const row = this._sprites[r];
          if (row !== undefined) {
            for (let c = row.length - 1; c >= 0; --c) {
              const cell: Sprite[] = row[c]?.filter(s => s.isDecoration === renderDecoration);
              if (cell !== undefined && cell.length > 0) {
                this._cachedSpriteTiles.push({
                  row: r / 2,
                  col: c / 2,
                  sprites: cell,
                });
              }
            }
          }
        }
      }
    }
    return this._cachedSpriteTiles.forEach(callback);
  }

  /**
   * If true, the given location can be occupied by a character.
   */
  canOccupy(location: MapLocation): boolean {
    const row = this._tiles[location.row];
    if (row !== undefined) {
      const col = row[location.col];
      if (col !== undefined) {
        return !col.blocksMovement;
      }
    }
    // We cannot occupy void space
    return false;
  }

  /**
   * If true, the given location blocks sound. If there is no path for sound to move from
   * one location to another, audio + video will be cut off. Traditionally, walls and doors
   * block sound. Few things should block sound that can be occupied (see {@link #canOccupy()}).
   */
  blocksSound(location: MapLocation): boolean {
    const row = this._tiles[location.row];
    if (row !== undefined) {
      const col = row[location.col];
      if (col !== undefined) {
        return col.blocksSound;
      }
    }
    // We cannot occupy void space
    return false;
  }

  /**
   * Runs a breadth-first search from the origin, traversing any tile that doesn't block sound, and returns
   * for every destination the distance to that destination. If a distance wasn't found, the index in the array
   * is undefined.
   *
   * @param origin The origin location. Presumably, our location.
   * @param destinations The list of destinations we're looking for.
   * @param maxDistance The maximum distance to search from our origin.
   */
  neighbors = (
    origin: MapLocation,
    destinations: Array<MapLocation>,
    maxDistance: number = FADE_END_DISTANCE,
  ): Array<number | undefined> => {
    const distances: Array<number | undefined> = [];
    const hash = (loc: {row: number, col: number}): string => {
      return `${loc.row}.${loc.col}`;
    };
    const seen: Set<string> = new Set();
    const fringe = [{
      distance: 0,
      ...origin
    }];
    let iters = 0;

    while (fringe.length > 0 && ++iters < 4000) {
      const node = fringe.shift();
      if (!node) {
        continue;
      }
      const {distance, row, col} = node;
      // Check if this is a destination
      destinations.forEach((dest, i) => {
        if (dest.row === row && dest.col === col) {
          const knownDistance = distances[i];
          distances[i] = knownDistance != null ? Math.min(knownDistance, distance) : distance;
        }
      });
      // Register in our cache
      const hashedNode = hash(node);
      if (seen.has(hashedNode)) {
        continue;  // short circuit
      }
      seen.add(hashedNode);
      // Push the children, if they're valid locations and close enough
      let candidates = [  // clockwise from the top center
        {distance: distance + 1, row: row - 1, col: col},
        {distance: distance + 1, row: row - 1, col: col + 1},
        {distance: distance + 1, row: row, col: col + 1},
        {distance: distance + 1, row: row + 1, col: col + 1},
        {distance: distance + 1, row: row + 1, col: col},
        {distance: distance + 1, row: row + 1, col: col - 1},
        {distance: distance + 1, row: row, col: col - 1},
        {distance: distance + 1, row: row - 1, col: col - 1},
        ];
      for (let candidate of candidates) {
        if (candidate.distance <= maxDistance && !this.blocksSound(candidate) && !seen.has(hash(candidate))) {
          // Add it to our fringe
          fringe.push(candidate);
        }
      }
    }

    return distances;
  }
}

/**
 * Render our map into a div. This is not done through React because React takes forever to
 * re-render a bunch of sprites. Instead, we render directly into a <div> and manipulate the <div>
 * in an unmanaged way from React.
 *
 * @param target The target div we're rendering into.
 * @param map The map we're rendering.
 * @param myPosition The sprite's initial position on the map.
 */
export function render(
  target: HTMLElement,
  map: OfficeMap,
  myPosition: MapLocation): () => void {
  // Create our canvas
  const app = new PIXI.Application({width: (map.cols + 1) * 32, height: (map.rows + 1) * 32});
  target.style.marginLeft = -(myPosition.col * 32) + "px";
  target.style.marginTop = -(myPosition.row * 32) + "px";
  target.appendChild(app.view);

  // Disable PIXI ticker (we don't need it to run its render loop)
  app.ticker.autoStart = false;
  app.ticker.stop();

  // The destroyer function
  let destroy: (() => void) | null = null;

  // Load PIXI
  app.loader
    .add(Array.from(map.spritesheets))
    .load(() => {
      map.forEachSpriteCell( (data: SpriteTile) => {
        data.sprites.forEach((sprite) => {
          const pSprite = sprite.toPixiSprite();
          pSprite.x = data.col * 32;
          pSprite.y = data.row * 32;
          app.stage.addChild(pSprite);
        })
      });
      app.render();
      destroy = () => {
        app.destroy(true);
      }
    });

  return () => {
    if (destroy) {
      destroy();
    }
  }
}

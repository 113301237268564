import React, {ReactElement} from 'react';
import CharacterSprite from "../CharacterSprite/CharacterSprite";
import {MapLocation, render as renderMap} from '../../maps/OfficeMap';
import Spinner from 'react-spinkit';
import './MapView.scss';
import Character, {CharacterInit} from "../../core/Character";
import VideoIcon from "../../icons/VideoIcon";
import AudioIcon from "../../icons/AudioIcon";
import SettingsIcon from "../../icons/SettingsIcon";
import SettingsModal from "../SettingsModal/SettingsModal";
import PersonIcon from "../../icons/PersonIcon";
import CharacterListPopout from "../CharacterListPopout/CharacterListPopout";
import {setPosition} from "../../core/storage";
import VideoList from "../VideoList/VideoList";
import ChatIcon from "../../icons/ChatIcon";
import ChatPopout from "../ChatPopout/ChatPopout";
import {observer} from "mobx-react";
import Office, {ViewMode} from "../../core/Office";
import {action} from "mobx";
import {play} from "../../core/sound";
import {Peer} from "../../core/PeerProvider";

type MapViewState = {
  /** If true, we are showing the settings modal. */
  settingsShowing: boolean,
  /** If true, we have chat open. */
  chatShowing: boolean,
  /** The number of chat messages we've read. Used to compute whether there are unread chats. */
  numChatMessagesRead: number,
}

type MapViewProps = {
  mapDiv: HTMLElement,
  office: Office,
  me: Character,
}

/**
 * Our main view: the map where we can walk around.
 */
@observer
export default class MapView extends React.Component<MapViewProps, MapViewState> {

  /**
   * Our local React state.
   */
  state: MapViewState;

  /**
   * A callback for clearing our current map, when we unmount.
   */
  _clearMap: (() => void) | null = null;

  constructor(props: MapViewProps) {
    super(props);
    this.state = {
      settingsShowing: false,
      numChatMessagesRead: 0,
      chatShowing: false,
    };
  }

  /**
   * Get the characters that are currently connected to this map, including ourselves.
   * This does a bit of sanity check validation on our own position as well, ensuring that we don't
   * overlap with others on the map.
   *
   * Note that this has to be an action since it can manipulate our character.
   */
  characters = action((): Array<Character> => {
    const {office, me} = this.props;

    // Get our peer characters
    const characters = Array.from(office.peerCharacters.values());

    // Validate our position
    while (!this._canOccupy(me.location, characters)) {
      const incr = Math.random() > 0.5 ? 1 : -1;
      if (Math.random() > 0.5) {
        me.location.row += incr;
      } else {
        me.location.col += incr;
      }
    }

    // Add ourselves
    characters.push(me);

    // Sort
    return characters.sort((a: Character, b: Character) => {
      if (a.id === me.id && b.id !== me.id) {
        return -1;
      } else if (b.id === me.id && a.id !== me.id) {
        return 1;
      } else {
        return a.id.localeCompare(b.id)
      }
    });
  });

  /**
   * Make sure that the location we're trying to occupy can be occupied.
   *
   * @param position The position we're trying to occupy.
   * @param characters An optional list of other characters on the map.
   *
   * @private
   */
  _canOccupy = (position: MapLocation, characters?: Array<Character>): boolean => {
    const {office: {map}, me} = this.props;
    if (!map.canOccupy(position)) {
      return false;
    }
    let anyColisions: boolean = false;
    (characters ? characters : this.characters()).forEach((character: Character) => {
      if (character.id !== me.id) {
        anyColisions = anyColisions ||
          (character.location.row === position.row && character.location.col === position.col);
      }
    });
    return !anyColisions;
  };

  /**
   * The main key listener listening for movement
   */
  keyListener = (e: KeyboardEvent) => {
    const {mapDiv, me, office} = this.props;
    const {settingsShowing} = this.state;
    if (settingsShowing) {
      return;
    }

    const myLocation = me.location;
    let newPosition = null;
    let newOrientation: 'up' | 'right' | 'down' | 'left' | null = null;
    switch (e.key) {
      case 'ArrowUp':
      case 'w':
        newPosition = {row: myLocation.row - 1, col: myLocation.col};
        newOrientation = 'up';
        break;
      case 'ArrowRight':
      case 'd':
        newPosition = {row: myLocation.row, col: myLocation.col + 1};
        newOrientation = 'right';
        break;
      case 'ArrowDown':
      case 's':
        newPosition = {row: myLocation.row + 1, col: myLocation.col};
        newOrientation = 'down';
        break;
      case 'ArrowLeft':
      case 'a':
        newPosition = {row: myLocation.row, col: myLocation.col - 1};
        newOrientation = 'left';
        break;
    }
    const update: CharacterInit = {id: me.id};
    // Resolve warping
    if (newPosition != null) {
      newPosition = this.props.office.map.resolveWarp(myLocation, newPosition);
    }
    if (newOrientation != null) {
      update.orientation = newOrientation;
    }
    if (newPosition != null && this._canOccupy(newPosition)) {
      // Set our position in local storage, so it persists through reloads
      setPosition(newPosition);
      // Queue the position for update
      update.location = newPosition;
      // Update our map
      mapDiv.style.marginLeft = -(newPosition.col * 32) + "px";
      mapDiv.style.marginTop = -(newPosition.row * 32) + "px";
    }
    me.updateFrom(update);

    // Resolve audio triggers
    for (let trigger of office.map.audioTriggers(me.location, me.orientation)) {
      // Play the trigger locally
      play(trigger.audioUrl);
      // Play the trigger for all our visible peers
      const origin: MapLocation = trigger.audioOrigin ? trigger.audioOrigin : trigger.triggerLocation;
      const peers: Array<Peer> = Array.from(office.peers.values());
      const peerLocations: Array<MapLocation> = peers.map(p => p.character.location)
      const peerDistances: Array<number|undefined> = office.map.neighbors(origin, peerLocations);
      for (let i = 0; i < peers.length; ++i) {
        const peer: Peer = peers[i];
        if (peerDistances[i] != null) {
          peer.sendMessage({
            type: 'audio',
            url: trigger.audioUrl,
          })
        }
      }
    }
  };

  /**
   * Called when this component is created (i.e., shown)
   */
  componentDidMount(): void {
    const {mapDiv, me, office: {map}} = this.props;
    const {location: myLocation } = me;

    // Render our map
    this._clearMap = renderMap(
      mapDiv,
      map,
      myLocation
    );

    // Listen for keyboard events
    // Must be on `window` because React listens on `document` and so we wouldn't
    // be able to stop propagation :(
    window.addEventListener('keydown', this.keyListener);
  }

  /**
   * Called when this component is destroyed (i.e., hidden)
   */
  componentWillUnmount(): void {
    // Stop listening for events
    window.removeEventListener('keydown', this.keyListener);
    // Clear our map
    if (this._clearMap) {
      this._clearMap();
    }
  }

  /** {@inheritDoc} */
  render() {
    // Get a bunch of information about the world
    const {office, me} = this.props;
    const {map, chatTranscript} = office;
    const {willHaveVideo, willHaveAudio} = me.camera;
    const {settingsShowing, numChatMessagesRead, chatShowing} = this.state;
    const characters = this.characters();

    // Character Sprites
    const characterSprites = characters.map( (character: Character) => {
      return <CharacterSprite
        key={character.id}
        character={character}
        origin={me.location}
      />;
    });

    // Control bar
    const video = <div
      className={`VideoIcon ${willHaveVideo ? 'VideoOn' : 'VideoOff'}`}
      onClick={() => office.toggleVideo(me.camera)}
    >
      <VideoIcon enabled={willHaveVideo}/>
    </div>;
    const audio = <div
      className={`AudioIcon ${willHaveAudio ? 'AudioOn' : 'AudioOff'}`}
      onClick={() => office.toggleAudio(me.camera)}
    >
      <AudioIcon enabled={willHaveAudio}/>
    </div>;
    const settingsIcon = <div
      className="SettingsIcon"
      onClick={() => this.setState({settingsShowing: !settingsShowing})}
    >
      <SettingsIcon/>
    </div>;

    // Settings
    let settings: ReactElement | null = null;
    if (settingsShowing) {
      settings = <SettingsModal me={me} close={() => this.setState({settingsShowing: false})}/>;
    }

    // Online Users
    let onlineUsersContents;
    if (office.connected) {
      onlineUsersContents = <>
          <div className="OnlineIconContainer">
            <PersonIcon/>
          </div>
          <div className="OnlineCount">
            {characters.length}
          </div>
          <CharacterListPopout characters={characters}/>
        </>;
    } else {
      onlineUsersContents = <Spinner name="double-bounce" className="IconContainer" fadeIn="none"/>;
    }
    const onlineUsers = <div
      className="OnlineUsers"
    >
      {onlineUsersContents}
    </div>;

    // Chat Widget
    const chat = <div
      className={`Chat ${numChatMessagesRead === chatTranscript.length || chatShowing ? 'ChatRead' : 'ChatUnread'}`}
      onMouseOver={() => {
        this.setState({numChatMessagesRead: chatTranscript.length, chatShowing: true});
      }}
      onMouseLeave={() => {
        this.setState({chatShowing: false});
      }}
    >
      <div className="ChatIconContainer">
        <ChatIcon/>
      </div>
      <ChatPopout
        transcript={chatTranscript}
        sendMessage={(msg) => office.chat(msg, me)}
        characters={office.peerCharacters}
      />
    </div>;

    // Conference blade
    const conferenceBlade = <VideoList
      me={me}
      characters={characters}
      neighbors={map.neighbors}
      setView={(view: ViewMode) => office.setView(view)}
    />

    // Render the components
    return (
      <div className="MapView">
        {characterSprites}

        {onlineUsers}
        {audio}
        {video}
        {settingsIcon}
        {chat}

        {settings}

        {conferenceBlade}
      </div>
    );
  }
}

import React from 'react';
import './CharacterSprite.scss';
import Character from "../../core/Character";
import {MapLocation} from "../../maps/OfficeMap";
import {observer} from "mobx-react";

type CharacterSpriteProps = {
  character: Character,
  origin: MapLocation,
}

/**
 * A rendered character sprite, with a name rendered on top of the sprite.
 * This handles the rendering, animation, and nametag for a character.
 */
const CharacterSprite = observer((props: CharacterSpriteProps) => {
  const {character, origin} = props;
  const {location, orientation, sprite, isIdle} = character;
  const spriteDef = sprite.sprite(location, orientation);
  const {row: originRow, col: originCol} = origin;
  const {row: myRow, col: myCol} = location;
  const leftOffset = myCol - originCol;
  const topOffset = myRow - originRow;

  return (
    <div className="PersonSprite" style={{
      background: `url(${spriteDef.sheet}) ${-spriteDef.offsetX}px ${-spriteDef.offsetY}px`,
      left: `calc(50% - 16px + ${leftOffset * 32}px)`,
      top: `calc(50% - 16px + ${topOffset * 32}px)`,
    }}>
      <div className="Name">
        <div className={`NameTag ${isIdle ? 'NameTagAway' : 'NameTagHere'}`}>
          {character.name}
        </div>
      </div>
    </div>
  )
});

export default CharacterSprite;

import React, {ReactElement, useEffect, useRef, useState} from 'react';
import './ChatPopout.scss';
import {ChatMessage} from "../../core/PeerProvider";
import Character from "../../core/Character";
import CharacterSpriteSpec from "../../sprites/CharacterSpriteSpec";
import {observer} from "mobx-react";
import {action} from "mobx";

type ChatPaneProps = {
  transcript: Array<ChatMessage>,
  sendMessage: (msg: string) => void,
  characters?: Map<string, Character>,
}

/**
 * The contents of the chat popout, consisting of the chat transcript and the "send message" bar.
 * This is what pops out when you hover over the chat icon.
 */
const ChatPopout = observer((props: ChatPaneProps) => {
  const {transcript, sendMessage, characters} = props;
  const [msg, setMsg] = useState('');
  const lastElemRef = useRef(null as HTMLDivElement | null);

  useEffect(() => {
    lastElemRef.current?.scrollIntoView({behavior: 'smooth' })
  }, [transcript.length]);

  let transcriptElems: Array<ReactElement> = [];
  for (let i = 0; i < transcript.length; ++i) {
    const lastSpeaker = i === 0 ? null : transcript[i - 1].speaker.id;
    const utterance = transcript[i];
    if (utterance.speaker.id !== lastSpeaker) {
      // Case: we need to add a divider
      // Get some basic info
      const currentCharacter = characters?.get(utterance.speaker.id);
      let name = utterance.speaker.name;
      let sprite = new CharacterSpriteSpec(utterance.speaker.sprite.gender, utterance.speaker.sprite.style);
      if (currentCharacter != null) {
        name = currentCharacter.name;
        sprite = currentCharacter.sprite;
        // Update the saved utterance in-place with the new data,
        // to make sure rendering remains stable if the character
        // leaves later.
        action(() => {
          if (currentCharacter.name !== utterance.speaker.name) {
            utterance.speaker.name = currentCharacter.name;
          }
          if (utterance.speaker.sprite.gender !== currentCharacter.sprite.gender ||
              utterance.speaker.sprite.style !== currentCharacter.sprite.style) {
            utterance.speaker.sprite = {
              gender: currentCharacter.sprite.gender,
              style: currentCharacter.sprite.style,
            };
          }
        })();
      }

      // Render the header
      const spriteDef = sprite.sprite(0, 'down');
      transcriptElems.push(<div
        key={`header-${i}`}
        className="ChatHeader"
      >
        <div className="Sprite" style={{
          background: `url(${spriteDef.sheet}) ${-spriteDef.offsetX}px ${-spriteDef.offsetY}px`,
        }}/>
        <div className="Name">{name}</div>
      </div>);
    }

    // Add the actual utterance
    transcriptElems.push(<div
      key={i}
      className="ChatMessage"
      ref={i === transcript.length - 1 ? lastElemRef : null}
    >{utterance.message}</div>);
  }

  return <div
    className="ChatPane"
    onKeyDown={(e) => {
      e.nativeEvent.stopPropagation();
    }}
  >
    <div className="ChatTranscript">
      {transcriptElems}
    </div>
    <div className="ChatInput">
      <input
        value={msg}
        onChange={(e) => {
          setMsg(e.target.value)
        }}
        onKeyDown={(e) => {
          if (msg.trim() !== '' && e.key === 'Enter') {
            sendMessage(msg);
            setMsg('');
          }
        }}
      />
    </div>
  </div>;
});

export default ChatPopout;
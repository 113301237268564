/**
 * A helper to play a given sound.
 *
 * @param url Either a url, or a special sound class (e.g., $knock) that picks one of a set
 *            of sounds at random.
 */
export const play = (url: string) => {
  let fullUrl: string = url;
  switch (url) {
    case '$knock':
//      fullUrl = `/sounds/knock${Math.floor(Math.random() * 5)}.mp3`
      fullUrl = `/sounds/knock4.mp3`
      break;
  }
  console.debug(`Playing sound ${fullUrl}`);
  const audio = new Audio(fullUrl);
  audio.volume = 1.0;
  audio.play();
  audio.onended = () => audio.remove();
}
import React, {Component} from 'react';
import './MeetingView.scss';
import Character from "../../core/Character";
import VideoPane from "../VideoPane/VideoPane";
import VideoIcon from "../../icons/VideoIcon";
import AudioIcon from "../../icons/AudioIcon";
import BackIcon from "../../icons/BackIcon";
import {FADE_END_DISTANCE, FADE_START_DISTANCE} from "../../env";
import {observer} from "mobx-react";
import Office from "../../core/Office";

type MeetingViewProps = {
  office: Office,
  me: Character,
}

type MeetingViewState = {
  windowWidth: number,
  windowHeight: number,
  connected: boolean,
  selectedCharacterId?: string,
}

/**
 * An alternate view where everyone's video shows up in a grid, similar to Google Meet's (or Zoom's) grid view.
 */
@observer
export default class MeetingView extends Component<MeetingViewProps, MeetingViewState> {

  state: MeetingViewState;

  constructor(props: MeetingViewProps) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      connected: false,
    }
  }

  /**
   * This is really kind of a dummy function. We set the state so that we force a re-render of the React
   * component.
   *
   * @private
   */
  _windowResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  };

  /**
   * Called when this component is created (i.e., shown)
   */
  componentDidMount(): void {
    // Listen on window sizing updates
    window.addEventListener('resize', this._windowResize);
  }

  /**
   * Called when this component is destroyed (i.e., hidden)
   */
  componentWillUnmount(): void {
    // Stop listening on window sizing updates
    window.removeEventListener('resize', this._windowResize);
  }

  /**
   * Construct our grid layout style. The result of this function should be fed directly
   * into the React style param of the div.
   *
   * @param numCharacters The number of characters we have chat active for.
   */
  gridLayout = (numCharacters: number): {gridTemplateRows: string, gridTemplateColumns: string}  => {
    const basis: number = Math.ceil(Math.sqrt(numCharacters));
    const isUneven: boolean = numCharacters <= (basis * (basis - 1));
    const haveMoreRows: boolean = window.innerWidth < window.innerHeight * 1.33;
    return {
      gridTemplateRows: '1fr '.repeat(basis - ((isUneven && !haveMoreRows) ? 1 : 0)),
      gridTemplateColumns: '1fr '.repeat(basis - ((isUneven && haveMoreRows) ? 1 : 0)),
    }
  };

  render() {
    const {me, office} = this.props;
    const {selectedCharacterId} = this.state;
    const {map, peerCharacters} = office;

    const characters = [me, ...Array.from(peerCharacters.values())];

    // Collect and sort our characters
    const charactersArray = Array.from(characters.values()).sort((a: Character, b: Character) => {
      if (a.id === selectedCharacterId) {
        return -1;
      } else if (b.id === selectedCharacterId) {
        return 1;
      } else if (a.id === me.id) {
        return -1;
      } else if (b.id === me.id) {
        return 1;
      } else {
        return a.id.localeCompare(b.id);
      }
    });

    // Compute distances
    const characterLocations = charactersArray.map(x => x.location);
    const distanceToCharacter: Array<number | undefined>
      = map.neighbors(me.location, characterLocations, FADE_END_DISTANCE);

    // Create the video panes
    const videoPanes = charactersArray.map((c: Character, characterIndex: number) => {
      // Compute visibility
      const distance: number | undefined = distanceToCharacter[characterIndex];
      let strength;
      if (distance == null) {
        strength = 0.0;
      } else if (distance < FADE_START_DISTANCE) {
        strength = 1.0;
      } else {
        strength = 1.0 - ((distance - FADE_START_DISTANCE) / (FADE_END_DISTANCE - FADE_START_DISTANCE));
      }
      if (strength > 0) {
        return <VideoPane
          key={c.id}
          character={c}
          isMe={c.id === me.id}
          strength={strength}
          style={{
            margin: 0,
            borderRadius: 0
          }}
        />;
      } else {
        return null;
      }
    }).filter(x => x != null);

    // Control bar
    const {willHaveVideo, willHaveAudio} = me.camera;
    const video = <div
      className={`VideoIcon ${willHaveVideo ? 'VideoOn' : 'VideoOff'}`}
      onClick={() => office.toggleVideo(me.camera)}
    >
      <VideoIcon enabled={willHaveVideo}/>
    </div>;
    const audio = <div
      className={`AudioIcon ${willHaveAudio ? 'AudioOn' : 'AudioOff'}`}
      onClick={() => office.toggleAudio(me.camera)}
    >
      <AudioIcon enabled={willHaveAudio}/>
    </div>;

    // Back to map view
    const mapIcon = <div
      className="MapModeButton"
      onClick={() => office.setView('map')}
    >
      <div className="MapIconContainer">
        <BackIcon/>
      </div>
      <div className="MapIconText">
        Back to Map View
      </div>
    </div>;

    return <div className="MeetingView">
      {mapIcon}

      <div className="VideosContainer" style={this.gridLayout(videoPanes.length)}>
        {videoPanes}
      </div>

      {audio}
      {video}
    </div>

  }
}

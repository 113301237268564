import React from 'react';
import './SettingsModal.scss';
import CharacterSpriteSelector from "../CharacterSpriteSelector/CharacterSpriteSelector";
import {setCharacterSprite, setName} from "../../core/storage";
import Character, {DEFAULT_NAME} from "../../core/Character";
import CharacterSpriteSpec from "../../sprites/CharacterSpriteSpec";
import CharacterNameSelector from "../CharacterNameSelector/CharacterNameSelector";
import {observer} from "mobx-react";

export type SettingsViewProps = {
  className?: string,
  me: Character,
  close: () => void,
}

/**
 * The contents of the setting modal that pops up from the map view.
 */
const SettingsModal = observer((props: SettingsViewProps) => {
  const {me, className, close} = props;

  // CloseButton
  const closeButton = <div className="CloseButton" onClick={close}>
    X
  </div>

  // Name
  const name = <div className="Pane NameSettings">
    <label>Your Name</label>
    <p>
      This name will be shown above your avatar in order to identify you.
    </p>
    <CharacterNameSelector
      onNameChosen={(name: string) => {
        me.updateFrom({
          id: me.id,
          name: name || DEFAULT_NAME,
        });
        setName(name || DEFAULT_NAME);
      }}
    />
  </div>;

  // Sprite Selector
  const spriteSelector = <div className="Pane SpriteSettings">
    <label>Character Sprite</label>
    <p>
      You and others on the map will see this avatar.
    </p>
      <CharacterSpriteSelector
        className="SpriteSelectorContainer"
        onSpriteSelected={(sprite: CharacterSpriteSpec) => {
        setCharacterSprite(sprite);
        me.updateFrom({
          id: me.id,
          sprite: {gender: sprite.gender, style: sprite.style},
        })
      }} />
  </div>;

  return <div className="SettingsVeil" onClick={close}>
    <div
      className={`SettingsView ${className ? className : ''}`}
      onClick={(e) => {  // don't propagate clicks out
        e.stopPropagation();
        e.preventDefault();
        return false;
      }}
    >
      {closeButton}
      <h2>Settings</h2>
      <div className="SettingsList">
        {name}
        {spriteSelector}
      </div>
    </div>;
  </div>;

});

export default SettingsModal;
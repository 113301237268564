import React, {useState} from 'react';
import './LandingPage.scss';
import CharacterSpriteSelector from "../CharacterSpriteSelector/CharacterSpriteSelector";
import CharacterSpriteSpec from "../../sprites/CharacterSpriteSpec";
import {
  setCharacterSprite as storeCharacterSprite,
  getName,
  setName as storeName,
  getLastRoomName,
  setLastRoomName as storeLastRoomName,
} from "../../core/storage";
import {Link, useHistory} from "react-router-dom";
import LockIcon from "../../icons/LockIcon";
import zxcvbn from "zxcvbn";
import {observer} from "mobx-react";
import fitzpatrick from "../../maps/fitzpatrick";
import SpriteKit from "../../sprites/SpriteKit";

/**
 * Our landing page. THis is mostly HTML, with a bit of JS sprinkled here and there for, e.g.,
 * password strength validation and setting our name+sprite.
 */
const LandingPage = observer((props: {}) => {
  const history = useHistory();
  const [roomName, setRoomName] = useState(getLastRoomName() || '');
  const [roomPasswordPlaintext, setRoomPasswordPlaintext] = useState('');
  const [characterName, setCharacterName] = useState(getName());

  const passwordStrength = zxcvbn(roomPasswordPlaintext || '');
  let security = '#B81D13';  // red
  if (passwordStrength.score === 4) {
    security = '#008450';  // green
  } else if (passwordStrength.score >= 2) {
    security = '#EFB700';  // yellow
  }

  fitzpatrick(SpriteKit.DEFAULT)

  return <div className="LandingPage">
    <div className="Hero">
      <h1>The Online Office</h1>
    </div>

    <div className="Intro">
      <div className="Description">
        <p>
          The Online Office simulates an office environment by allowing you to <b>walk around</b> a
          virtual office, while on <b>videochat</b> with nearby teammates. As you move around
          the audio and video of anyone nearby fades in, and those further away fade out. Moving between
          rooms in the office also changes the teammates you're currently on a call with.
        </p>

        <p>
          We hope the site can facilitate a more social workplace and provides an easier way to have impromptu
          conversations and ask teammates question. If you have feedback or suggestions we'd love
          to hear from you at <a href={"mailto:hello@weworkonline.us"}>hello@weworkonline.us</a>.

        </p>

        <p>
          All of the connections are
          entirely <b>peer-to-peer and encrypted</b>,<sup><a href="https://webrtc-security.github.io/">[1]</a></sup> protecting
          your privacy as you talk with your co-workers. None of your data is stored anywhere.
          Moreover, we only send data to officemates who are within earshot to help preserve
          bandwidth and keep the office running smoothly.
          Being peer-to-peer also means we can keep the site completely free forever, since there are no
          backend servers to maintain.
        </p>

        <p>
          Get started with your own office below!
        </p>
      </div>
      <div className="Demo">
        <img className="DemoImage" src="/demo.png" alt="Online Office demo screenshot"/>
      </div>
    </div>

    <div className="Steps">
      <div className="Step">
        <label>Step 1</label>
        <h3>Setup your avatar</h3>
        Your avatar is what you look like to others as you move around the map, and your
        name will show up above your avatar.
        You can always change your avatar and name from the settings menu after joining.

        <input
          placeholder="Your Name"
          value={characterName || ''}
          onChange={(e) => {
            setCharacterName(e.target.value);
            storeName(e.target.value);
          }}
          tabIndex={0}
        />

        <div style={{marginTop: 48}}>
          <CharacterSpriteSelector onSpriteSelected={(sprite: CharacterSpriteSpec) => {
            storeCharacterSprite(sprite);
          }}/>
        </div>
      </div>


      <div className="Step">
        <label>Step 2</label>
        <h3>Pick an office name</h3>
        <p>
          You will join an office with others that have chosen the same name.
          If you'd like to invite others to your office simply send them the office name{
            roomName ? <span>, or link them
          to <a
                tabIndex={4}
                href={`${document.location.href}r/${roomName.replace(' ', '%20') || '<room_name>'}`}>
          {document.location.href}r/{roomName.replace(' ', '%20') || '<room_name>'}
        </a>.
        </span> : '.'}
        </p>

        <form
          onSubmit={(e) => {
            history.push({
              pathname: `/r/${roomName}`,
              state: {
                passwordPlaintext: roomPasswordPlaintext
              }
            });
            e.preventDefault();
            return false;
          }}
        >

          <input
            id="username"
            type="text"
            placeholder="Office Name"
            value={roomName}
            onChange={(e) => {
              setRoomName(e.target.value);
              storeLastRoomName(e.target.value);
            }}
            tabIndex={1}
          />

          <p style={{marginTop: 48}}>
            You can additionally set a password on your office. You will only be connected to
            others if they enter both the same office name and password as you. If you mistype
            this password, it will start a new office instead.
          </p>

          <div className="Password">
            <div className="LockIcon" style={{fill: security}}>
              <LockIcon/>
            </div>
            <input
              type="password"
              placeholder="Office Password"
              value={roomPasswordPlaintext || ''}
              onChange={(e) => {
                setRoomPasswordPlaintext(e.target.value)
              }}
              tabIndex={2}
            />
          </div>
        </form>
      </div>


      <div className="Step">
        <label>Step 3</label>
        <h3>Join!</h3>
        Once you've set your office name and avatar, click the button below to join!
        <div className="JoinButtonContainer">
          {roomName
          ? <Link to={{
              pathname: `/r/${roomName}`,
              state: {
                passwordPlaintext: roomPasswordPlaintext
              },
            }}>
              <button tabIndex={3}>Enter the office</button>
            </Link>
          : <button disabled tabIndex={3}>Please set an office name</button>}
        </div>
      </div>
    </div>
  </div>

});

export default LandingPage;
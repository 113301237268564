import React, {ReactElement} from 'react'
import './CharacterListPopout.scss'
import Character from "../../core/Character";
import {observer} from 'mobx-react';

type CharacterListProps = {
  characters: Array<Character>,
  className?: string,
};

/**
 * A list of characters that are currently online. This is the content of the "online characters"
 * popout at the bottom of the screen.
 */
const CharacterListPopout = observer((props: CharacterListProps) => {
  const {characters, className} = props;
  let listItems: Array<ReactElement> = [];
  characters.forEach((character: Character) => {
    const spriteDef = character.sprite.sprite(0, 'down');
    listItems.push(<li key={character.id}>
      <div className="Sprite" style={{
        background: `url(${spriteDef.sheet}) ${-spriteDef.offsetX}px ${-spriteDef.offsetY}px`,
      }}/>
      <div className="Name">{character.name}</div>
    </li>);
  });

  if (listItems.length > 10) {
    listItems = listItems.slice(0, 9);
    listItems.push(<li>... {characters.length - 9} others</li>)
  }

  return <div className="CharacterList">
    <ul className={className || ''}>
      {listItems}
    </ul>
  </div>;

});

export default CharacterListPopout;
// Useful resource: https://www.pkc.io/blog/untangling-the-webrtc-flow/
// The "perfect negotiation" race conditions: https://blog.mozilla.org/webrtc/perfect-negotiation-in-webrtc/
import Camera from "./Camera";
import {observable} from "mobx";

/**
 * The type of the callback to send an SDP message to our peer.
 * These are out-of-band messages that have to be sent through some other method.
 */
export type SendSdpFunction = (offer: RTCSessionDescriptionInit) => void;

/**
 * The type of the callback to send an ICE candidate to our peer.
 * These are out-of-band messages that have to be sent through some other method.
 */
export type SendIceCandidateFunction = (candidate: RTCIceCandidateInit) => void;

/**
 * This class defines an RTC Peer connection. This wraps the peer connection itself,
 * alongside utilities for managing the video+audio streams we send + receive from the
 * peer. This class makes no assumptions about the out-of-band signaling that happens.
 */
export default class WebRtcConnection {

  /**
   * Our underlying connection. This is the raw connection as implemented
   * by the browser.
   */
  @observable conn: RTCPeerConnection;

  /**
   * The out-of-band mechanism we use to send an SDP message when needed.
   * This class is agnostic to that method.
   */
  sendSdp: SendSdpFunction;

  /**
   * The out-of-band mechanism we use to send an ICE candidates when needed.
   * This class is agnostic to that method.
   */
  sendIceCandidate: SendIceCandidateFunction;

  /**
   * If true, we are on the host side of this connection. This is used in part
   * to manage the connection state, and in part to enforce that one of our peers
   * is "polite". See https://blog.mozilla.org/webrtc/perfect-negotiation-in-webrtc/
   */
  @observable amHost: boolean;

  /**
   * An RTC data channel for sending text-based messages to and from our peer.
   */
  _channel: RTCDataChannel | null = null;

  /**
   * If true, our text channel {@link _channel} is open and able to send messages.
   */
  channelOpen: boolean = false;

  /**
   * By default we queue up messages if our data channel is unavailable. This is
   * the structure that holds that queue.
   */
  _queuedMessages: Array<string> = [];

  /**
   * If true, allow sending high-bandwidth media (audio+video) over this channel.
   * This defaults to false, but should be immediately updated as soon as our map loads.
   */
  _allowMedia: boolean = false;

  /**
   * The current offer we should be sending to the remote. This indicates whether we're willing to
   * accept audio and/or video from the peer.
   */
  _currentOffer: () => RTCOfferOptions;

  /**
   * State tracking for perfect negotiation. See https://w3c.github.io/webrtc-pc/#perfect-negotiation-example
   * This tracks whether we're currently in the process of making an offer, to mitigate race conditions
   * that may arise from simultaneous offer negotiation.
   */
  _makingOffer: boolean = false;

  /**
   * The maximum message size we can send to our remote. The initial value here
   * is an estimate; this should be set from the remote SDP message once known.
   */
  maxMessageSize = 16384;

  /**
   * Create a new peer connection, taking as input our camera, an empty camera into which
   * we should place our remote video, and the pair of out-of-band signaling channels.
   */
  constructor(
    remoteCamera: Camera,
    sendSdp: SendSdpFunction,
    sendIceCandidate: SendIceCandidateFunction,
    amHost: boolean,
    currentOffer: () => RTCOfferOptions = () => ({offerToReceiveAudio: true, offerToReceiveVideo: true}),
  ) {
    this.amHost = amHost;
    this.sendSdp = (offer) => {
      sendSdp(offer);
    };
    this.sendIceCandidate = (candidate) => {
      sendIceCandidate(candidate);
    };
    this._currentOffer = currentOffer;

    this.conn = new RTCPeerConnection({
      iceServers: [
        {
          urls: [
            'stun:stun.l.google.com:19302',
            'stun:stun1.l.google.com:19302',
            'stun:stun2.l.google.com:19302',
            'stun:stun3.l.google.com:19302',
            'stun:stun4.l.google.com:19302',
          ]
        }
      ],
    });
    this.conn.onicecandidate = (ev: RTCPeerConnectionIceEvent): any => {
      if (ev && ev.candidate) {
        this.sendIceCandidate(ev.candidate.toJSON());
      }
      return Promise.resolve();
    };
    this.conn.onnegotiationneeded = this._negotiate;
    this.conn.ontrack = ({track, streams}): any => {
      console.debug('RTC: ontrack() received track=', track.id, `on ${streams.length} streams. first stream=`,
        streams[0].id);
      const [stream] = streams;
      remoteCamera.addMedia(track, stream, true);
    };
  }

  /**
   * Designate one of the two peers as polite. In practice, this is an alias
   * for not being the host. It's abstracted here to conform to the naming used
   * in the perfect negotiation examples: https://w3c.github.io/webrtc-pc/#perfect-negotiation-example
   *
   * @private
   */
  get _polite() {
    return !this.amHost;
  }

  /**
   * Called when we need to [re-]negotiate our RTC state
   *
   * @private
   */
  _negotiate = async (): Promise<void> => {
    console.debug('RTC: _negotiate()', this.amHost ? 'as host' : 'as client', 'from state', this.conn.signalingState);
    this._makingOffer = true;
    try {
      const offer: RTCSessionDescriptionInit = await this.createOffer();
      if (this.conn.signalingState !== 'stable') {
        // in case our state changes since we created an offer, return immediately
        // This is from the race described in: https://blog.mozilla.org/webrtc/perfect-negotiation-in-webrtc/
        console.warn('Aborting negotiation from a non-stable state');
        return;
      }
      await this.setLocalDescription(offer);
      return this.sendSdp(offer);
    } finally {
      this._makingOffer = false;
    }
  };

  createOffer = (): Promise<RTCSessionDescriptionInit> => {
    const offer = this._currentOffer();
    console.debug('RTC: createOffer()', offer);
    return this.conn.createOffer(offer);
  };

  setLocalDescription = (offer: RTCSessionDescriptionInit): Promise<void> => {
    console.debug('RTC: setLocalDescription()', offer);
    return this.conn.setLocalDescription(offer);
  };

  setRemoteDescription = (offer: RTCSessionDescriptionInit): Promise<void> => {
    console.debug('RTC: setRemoteDescription()', offer);
    try {
      const msgSizeMatch = offer.sdp?.match(/a=max-message-size:\s*(\d+)/);
      if (msgSizeMatch && msgSizeMatch.length >= 2) {
        const msgSize = parseInt(msgSizeMatch[1]);
        if (msgSize !== this.maxMessageSize && msgSize > 1026) {
          console.debug('RTC: setRemoteDescription() setting max message size to', msgSize);
          this.maxMessageSize = msgSize;
        }
      }
    } catch (e) {
      console.debug('RTC: setRemoteDescription() could not determine max message size');
    }
    return this.conn.setRemoteDescription(offer);
  };

  createAnswer = (): Promise<RTCSessionDescriptionInit> => {
    const answer = this._currentOffer();
    console.debug('RTC: createAnswer()', answer);
    return this.conn.createAnswer(answer);
  };

  onOfferReceived = async (offer: RTCSessionDescriptionInit): Promise<void> => {
    console.debug('RTC: onOfferReceived()', offer);

    // If we should ignore the offer, return immediately
    const offerCollision = this._makingOffer || this.conn.signalingState !== 'stable';
    const ignoreOffer = !this._polite && offerCollision;
    if (ignoreOffer) {
      console.warn('RTC: onOfferReceived() detected synchronous negotiation. Ignoring the offer as the impolite peer');
      return;
    }

    // Set our remote description
    if (offerCollision) {
      // The client is polite and will roll back to accept the new offer
      // Note that this block of code should be removed once the following is merged and deployed live:
      // https://bugs.chromium.org/p/chromium/issues/detail?id=1085748
      // Firefox 77 should also have this fixed.
      console.warn('RTC: onOfferReceived() detected synchronous negotiation. Rolling back as the polite peer');
      // Note: Promise.all() is required here to prevent a race condition with
      // ICE candidates. See https://blog.mozilla.org/webrtc/perfect-negotiation-in-webrtc/
      try {
        await Promise.all([
          this.conn.setLocalDescription({type: 'rollback'}),
          this.setRemoteDescription(offer)
        ]);
      } catch (e) {
        console.warn("Rollback failed; Note that Chrome doesn't support rollback yet");
      }
    } else {
      await this.conn.setRemoteDescription(offer);  // Eventually, this should roll back automatically if needed
      console.debug('RTC: onOfferReceived() has set the remote description');
    }

    // Send an answer back
    const answer: RTCSessionDescriptionInit = await this.createAnswer();
    await this.setLocalDescription(answer);
    console.debug('RTC: onOfferReceived() is sending an answer back', answer);
    this.sendSdp(answer);
  };

  onAnswerReceived = (offer: RTCSessionDescriptionInit): Promise<void> => {
    console.debug('RTC: onAnswerReceived()', offer);
    return this.setRemoteDescription(offer);
  };

  onIceCandidateReceived = (candidate: RTCIceCandidateInit): void => {
    console.debug('onIceCandidateReceived:', candidate);
    this.conn.addIceCandidate(new RTCIceCandidate(candidate))
      .catch((e) => console.warn('Failed to add ICE candidate', e));
  };

  sendMessage = (msg: string, transient: boolean = false): void => {
    if (this.channelOpen && this._channel != null) {
      this._channel.send(msg);
    } else if (!transient) {
      this._queuedMessages.push(msg);
    }
  };

  /**
   * Adds a track to be sent to our peer. If this track is already being sent, this is a NOOP. Otherwise,
   * the track is added to the peer connection.
   *
   * @param track The track to send.
   * @param media The media stream this track is attached to.
   * @private
   */
  addTrack(track: MediaStreamTrack, media: MediaStream): void {
    console.debug('RTC: _addTrack()', 'track=', track.id, 'stream=', media.id,
      'state=', this.conn.signalingState, 'amHost=', this.amHost);
    this.conn.addTrack(track, media);
  }

  /**
   * Remove a track from our WebRTC connection.
   *
   * @param track The track we're removing
   *
   * @private
   */
  removeTrack(track: MediaStreamTrack): void {
    console.debug('RTC: _removeTrack() for track=', track.id,
      'state=', this.conn.signalingState, 'amHost=', this.amHost);
    for (let sender of this.conn.getSenders()) {
      if (sender.track?.id === track.id) {
        this.conn.removeTrack(sender);
        return;
      }
    }
    console.warn('RTC: _removeTrack() could not find sender for track=', track.id);
  }

  _call = (onMessage: (msg: string) => void): void => {
    console.debug('RTC: ☎ calling client');
    // Text connection
    this._channel = this.conn.createDataChannel('metadata', {
      id: 0,
      ordered: true,
    });
    this._channel.onopen = () => {
      this.channelOpen = true;
      for (let msg of this._queuedMessages) {
        this._channel?.send(msg);
      }
    };
    this._channel.onmessage = (msg: MessageEvent) => {
      onMessage(msg.data)
    };
  };

  _listen = (onMessage: (msg: string) => void): void => {
    console.debug('RTC: ☎ listening for host');
    // Text connection
    this.conn.ondatachannel = (ev: RTCDataChannelEvent) => {
      console.debug('RTC: got data channel as client');
      this._channel = ev.channel;
      this._channel.onopen = () => {
        console.debug('RTC: data channel is open as client');
        this.channelOpen = true;
        for (let msg of this._queuedMessages) {
          this._channel?.send(msg);
        }
      };
      this._channel.onmessage = (msg: MessageEvent) => {
        onMessage((msg.data));
      }
    };
  };

  connect = (onMessage: (msg: string) => void): void => {
    if (this.amHost) {
      this._call(onMessage);
    } else {
      this._listen(onMessage);
    }
  }

  close = () => {
    this._channel?.close();
    this.conn.close();
  }
}

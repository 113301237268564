import {WallKit, WhiteWalls} from "./WallSprites";
import {EllenceFloors, FloorKit} from "./FloorSprites";
import {DoorKit, EllenceWoodDoor} from "./DoorSprites";
import {EllenceFurniture, FurnitureKit} from "./FurnitureKit";

export default class SpriteKit {

  walls: WallKit
  floors: FloorKit
  doors: DoorKit
  furniture: FurnitureKit

  constructor(
      walls: WallKit,
      floors: FloorKit,
      doors: DoorKit,
      furniture: FurnitureKit,
  ) {
    this.walls = walls;
    this.floors = floors;
    this.doors = doors;
    this.furniture = furniture;
  }

  static DEFAULT = new SpriteKit(new WhiteWalls(), new EllenceFloors(), new EllenceWoodDoor(), new EllenceFurniture());

}
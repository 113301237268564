import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import './App.scss';
import Character, {DEFAULT_NAME} from "./core/Character";
import Camera, {CameraEnabledState} from "./core/Camera";
import {v4 as uuid} from 'uuid';
import {getCharacterSprite, getName, getPosition} from "./core/storage";
import LandingPage from "./components/LandingPage/LandingPage";
import {observer} from "mobx-react";
import AppState from "./AppState";
import OfficeView from "./components/OfficeView/OfficeView";
import Office from "./core/Office";
import SharedValue from "./core/SharedValue";
import {MapLocation} from "./maps/OfficeMap";
import Visibility from "visibilityjs";


/**
 * The cached variable for our character. Used in {@link ensureMe}.
 */
let me: Character | null = null;

/**
 * Create our character if it doesn't exist.
 * Note that invoking this function will, on first run, ask the user for access to their camera + microphone.
 */
function ensureMe(spawnLocation: MapLocation | undefined): Character {
  if (me == null) {

    // Get our camera
    const myCamera: Camera = new Camera(SharedValue.local({
      haveAudio: false,
      haveVideo: false,
    } as CameraEnabledState));
    myCamera.enableVideo(true, []);
    myCamera.enableAudio(true, []);

    // Create our character
    me = new Character({
      id: uuid(),
      location: getPosition() || spawnLocation || {row: 1, col: 1},
      orientation: 'down',
      name: getName() || DEFAULT_NAME,
      sprite: getCharacterSprite() || undefined,
    }, myCamera);

    // Listen for idleness
    Visibility.change((e: Event, state: string) => {
      if (Visibility.hidden()) {
        console.debug('My character is going idle');
        me?.updateFrom({
          id: me?.id,
          wentIdleAt: Date.now(),
        });
      } else {
        console.debug('My character returned from idleness');
        me?.updateFrom({
          id: me?.id,
          wentIdleAt: -1,
        });
      }
    });

  }
  return me;
}

const App = observer((props: {mapDiv: HTMLElement, globalState: AppState}) => {
  const {mapDiv, globalState} = props;

  // Set up our app
  return (
    <Router>
      <Switch>
        <Route path="/r/:officeName" render={({match, location: {state}}) => {
          if (globalState.currentOffice == null || globalState.currentOffice.name !== match.params.officeName) {
            // ensure that our office is on the stack
            globalState.visitOffice({
              name: match.params.officeName,
              passwordPlaintext: (state as any)?.passwordPlaintext,
            }, (office: Office) => ensureMe(office.map.spawnPosition));
          }
          return <OfficeView
            office={globalState.currentOffice as Office}
            ensureMe={() => ensureMe(globalState.currentOffice?.map.spawnPosition)}
            mapDiv={mapDiv}/>;
        }}>
        </Route>
        <Route path="/">
          <LandingPage />
        </Route>
      </Switch>
    </Router>
  );
});

export default App;

import React, {ChangeEvent, useState} from 'react';
import './CharacterNameSelector.scss'
import {getName} from "../../core/storage";
import {DEFAULT_NAME} from "../../core/Character";
import {observer} from "mobx-react";

type CharacterNameSelectorProps = {
  onNameChosen?: (name: string) => void,
  className?: string,
}

/**
 * A small widget to select a name for our character. It handles the styling of the input, and the
 * saving of the name in local storage.
 */
const CharacterNameSelector = observer((props: CharacterNameSelectorProps) => {
  const {onNameChosen, className} = props;
  const [name, setName] = useState(getName() || DEFAULT_NAME);

  return <div className={`CharacterNameSelector ${className ? className : ''}`}>
    <input
      placeholder="Enter a name..."
      value={name}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
        if (onNameChosen) {
          onNameChosen(e.target.value);
        }
      }}
    />
  </div>;
});

export default CharacterNameSelector;
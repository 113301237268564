import {action, IObservableValue, observable} from "mobx";

/**
 * The current timestamp, wrapped in an observable so that we update it when it changes.
 */
let currentTimestampMillisValue: IObservableValue<number> = observable.box(Date.now());

/**
 * Update the current timestamp every half second or so.
 */
setInterval(action(() => {
  currentTimestampMillisValue.set(Date.now());
}), 500);

export const currentTimestampMillis: () => number = () => {
  return currentTimestampMillisValue.get();
};
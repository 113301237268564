import React from 'react';
import {observer} from "mobx-react";

// From https://thenounproject.com/search/?q=exit%20back&i=2364564
const BackIcon = observer(() => {
  return <svg height='100%' viewBox="0 0 48 48" x="0px" y="0px">
    <title>Back</title>
    <g>
      <rect x="36.65" y="18.48" width="2.44" height="11"/>
      <path d="M41.38,29.48H45c0.28,0,0.5-0.22,0.5-0.5v-10c0-0.28-0.22-0.5-0.5-0.5h-3.62V29.48z"/>
      <g>
        <rect x="24" y="18.48" width="10.35" height="11"/>
        <path d="M2.75,24.41l19,11.02c0.16,0.09,0.35,0.09,0.5,0c0.15-0.09,0.25-0.25,0.25-0.43v-1.96v-3.56v-11V13    c0-0.18-0.1-0.34-0.25-0.43c-0.08-0.04-0.16-0.07-0.25-0.07s-0.17,0.02-0.25,0.07l-19,10.98C2.6,23.64,2.5,23.8,2.5,23.98    C2.5,24.16,2.59,24.32,2.75,24.41z"/>
      </g>
    </g>
  </svg>;

});

export default BackIcon;

import Office from "../../core/Office";
import LobbyView from "../LobbyView/LobbyView";
import MapView from "../MapView/MapView";
import MeetingView from "../MeetingView/MeetingView";
import React from "react";
import Character from "../../core/Character";
import {observer} from "mobx-react";


const OfficeView = observer((props: {
  office: Office,
  ensureMe: () => Character,
  mapDiv: HTMLElement,
}) => {
  const {office, ensureMe, mapDiv} = props;
  switch (office.view) {
    default:
    case 'lobby':
      return <div className="AppContainer">
        <LobbyView
          office={office}
          ensureMe={ensureMe}
        />
      </div>;
    case 'map':
      if (!office.joined) {
        office.join(ensureMe())
      }
      return <div className="AppContainer">
        <MapView
          office={office}
          mapDiv={mapDiv}
          me={ensureMe()}
        />
      </div>;
    case 'meeting':
      if (!office.joined) {
        office.join(ensureMe())
      }
      return <div className="AppContainer">
        <MeetingView
          office={office}
          me={ensureMe()}
        />
      </div>;
  }
});

export default OfficeView;
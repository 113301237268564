import React from 'react';
import {observer} from "mobx-react";

// From https://thenounproject.com/term/chat/945230/
const ChatIcon = observer(() => {
  return <svg height='100%' viewBox="0 0 100 100" x="0px" y="0px">
    <title>Chat</title>
    <path fill="none" d="M85.15,12.13H14.85A8.86,8.86,0,0,0,6,21V60.12A8.86,8.86,0,0,0,14.85,69H26V85.44L42.1,70.56,43.83,69H85.15A8.86,8.86,0,0,0,94,60.12V21A8.86,8.86,0,0,0,85.15,12.13ZM29.72,47.22a5.75,5.75,0,1,1,5.71-5.67A5.73,5.73,0,0,1,29.72,47.22Zm20.22,0a5.75,5.75,0,1,1,5.8-5.72A5.81,5.81,0,0,1,49.94,47.22Zm20.34,0A5.75,5.75,0,1,1,76,41.43,5.74,5.74,0,0,1,70.28,47.22Z"/>
    <path d="M85.15,6.13H14.85A14.85,14.85,0,0,0,0,21V60.12A14.85,14.85,0,0,0,14.85,75H20v13c0,3.85,1.44,5.93,3.63,5.93,1.32,0,2.9-.75,4.61-2.33L46.18,75h39A14.85,14.85,0,0,0,100,60.12V21A14.85,14.85,0,0,0,85.15,6.13Zm8.85,54A8.86,8.86,0,0,1,85.15,69H43.83l-1.72,1.6L26,85.44V69H14.85A8.86,8.86,0,0,1,6,60.12V21a8.86,8.86,0,0,1,8.85-8.85H85.15A8.86,8.86,0,0,1,94,21V60.12Z"/>
    <path d="M50,35.72a5.75,5.75,0,1,0,5.73,5.78A5.8,5.8,0,0,0,50,35.72Z"/>
    <path d="M29.77,35.72a5.75,5.75,0,1,0,5.67,5.82A5.73,5.73,0,0,0,29.77,35.72Z"/>
    <path d="M70.33,35.72A5.75,5.75,0,1,0,76,41.43,5.73,5.73,0,0,0,70.33,35.72Z"/>
  </svg>;

})

export default ChatIcon;

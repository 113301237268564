import CharacterSpriteSpec from "../sprites/CharacterSpriteSpec";
import {MapLocation, OfficeMap} from "../maps/OfficeMap";
import Camera from "./Camera";
import {action, computed, observable} from "mobx";
import {Peer} from "./PeerProvider";
import {currentTimestampMillis} from "./time";

export const DEFAULT_NAME = 'Unnamed';

/**
 * A JSON serializable representation of a character, suitable to be sent over the
 * wire. This does not include any actual streaming data, and only contains minimal sprite
 * information.
 */
export type CharacterInit = {
  id: string,
  location?: MapLocation,
  orientation?: 'up' | 'right' | 'down' | 'left',
  name?: string,
  wentIdleAt?: number,
  sprite?: {
    gender: 'male' | 'female',
    style: number,
  },
  haveVideo?: boolean,
  haveAudio?: boolean,
}

/**
 * The data representation of a character, including their location on the map.
 */
export default class Character {
  /**
   * A unique ID for this character. This is used to distinguish this character from
   * others, and to uniquely identify the character in messages sent to peers.
   */
  id: string;

  /**
   * The human-readable name for this character. This shows up in their nametag and
   * other places in the UI.
   */
  @observable name: string;

  /**
   * The sprite chosen for this character.
   * The actual sprite image can be retrieved by calling the #sprite() function
   * on the sprite spec defined here.
   */
  @observable sprite: CharacterSpriteSpec;

  /**
   * The location of this character on the map. This defines a row and column
   * on the 32px grid.
   */
  @observable location: MapLocation;

  /**
   * The orientation this character is facing.
   */
  @observable orientation: 'up' | 'right' | 'down' | 'left';

  /**
   * The timestamp in epoch millis when this user went idle. If this is a negative
   * value, the character is not idle.
   */
  @observable wentIdleAt: number;

  /**
   * The character's camera. This contains the audio+video stream that are attached
   * to this character.
   */
  @observable camera: Camera;

  /**
   * If false, this character has disconnected and is no longer a part of
   * the office.
   */
  @observable _open: boolean = true;


  constructor(init: CharacterInit, camera: Camera) {
    this.id = init.id;
    this.location = init.location || {row: 1, col: 1};
    this.orientation = init.orientation || 'down';
    this.name = init.name || 'Unnamed';
    if (init.sprite) {
      this.sprite = new CharacterSpriteSpec(init.sprite.gender, init.sprite.style);
    } else {
      this.sprite = new CharacterSpriteSpec(Math.random() < 0.5 ? 'male' : 'female', Math.round(Math.random() * 6));
    }
    this.wentIdleAt = init.wentIdleAt ? init.wentIdleAt : -1;
    this.camera = camera;
  }

  /**
   * Close this character. Stop sending updates, and stop the character's camera.
   * This should, generally speaking, be only called on ourselves.
   */
  close(peers: Array<Peer>) {
    this.camera.close(peers);
    this._open = false;
  }

  /**
   * If true, this character is considered idle.
   */
  @computed get isIdle(): boolean {
    return this.wentIdleAt >= 0 && this.wentIdleAt < currentTimestampMillis() - 3000;
  }

  /**
   * Update this character from the argument. This is traditionally called when we
   * receive a character update from a peer, but can also be used locally to update
   * certain aspects of the character.
   *
   * @param other The character to use to update this character from. Usually received
   *              over the network from a peer.
   * @param map The map we're on. We use this to compute trigger events for when the
   *            character moves.
   */
  @action updateFrom(other: CharacterInit, map?: OfficeMap): void {
    if (this.id !== other.id) {
      throw new Error('Cannot update a character with a different ID');
    }
    this.name = other.name || this.name;
    if (other.location && (this.location.row !== other.location.row || this.location.col !== other.location.col)) {
      // Case: this character has moved
      this.location = other.location;
    }
    this.orientation = other.orientation || this.orientation;
    if (other.sprite != null) {
      this.sprite = new CharacterSpriteSpec(other.sprite.gender, other.sprite.style);
    }
    if (other.wentIdleAt != null) {
      this.wentIdleAt = other.wentIdleAt;
    }
  }

  /**
   * Write this character into a serialized format that can be transmitted over the wire.
   * This is the companion to {@link #updateFrom()}.
   */
  serialize(): CharacterInit {
    return {
      id: this.id,
      location: this.location,
      orientation: this.orientation,
      name: this.name,
      sprite: {
        gender: this.sprite.gender,
        style: this.sprite.style
      },
      wentIdleAt: this.wentIdleAt,
    }
  }
}
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as PIXI from 'pixi.js';
import AppState from "./AppState";
import {configure} from "mobx";
// import 'mobx-react/batchingForReactDom';  // enabling this causes a setState() warning on MapView :/
import 'mobx-react/batchingOptOut'

// Configure Mobx
configure({
  enforceActions: 'observed',
})

// Disable PIXI message
PIXI.utils.skipHello();

// Get the map
const map = document.getElementById('map');
if (map == null) {
  throw Error('Could not find map div to render into');
}

// Create our global state
const globalState = new AppState();

ReactDOM.render(
  <React.StrictMode>
    <App mapDiv={map} globalState={globalState}/>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

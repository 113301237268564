import * as PIXI from 'pixi.js';

export default class Sprite {
  sheet: string;
  offsetX: number;
  offsetY: number;
  width: number;
  height: number;
  blocksMovement: boolean;
  blocksSound: boolean;
  isDecoration: boolean;

  _pixi: PIXI.Sprite | null = null;

  constructor(pageUrl: string, offsetX: number, offsetY: number,
              width: number = 32, height: number = 32,
              blocksMovement: boolean = false,
              blocksSound: boolean = false,
              isDecoration: boolean = false) {
    this.sheet = pageUrl;
    this.offsetX = offsetX;
    this.offsetY = offsetY;
    this.width = width;
    this.height = height;
    this.blocksMovement = blocksMovement;
    this.blocksSound = blocksSound;
    this.isDecoration = isDecoration
  }

  style() {
    return {
      width: this.width,
      height: this.height,
      background: `url(-${this.sheet}) -${this.offsetX}px ${this.offsetY}px`
    }
  }

  toPixiSprite(): PIXI.Sprite {
    const frame = new PIXI.Rectangle(this.offsetX, this.offsetY, this.width, this.height);
    const texture = new PIXI.Texture(PIXI.utils.TextureCache[this.sheet], frame);
    return new PIXI.Sprite(texture);
  }

  static decoration(sheet: string, offsetX: number, offsetY: number, width: number = 16, height: number = 16): Sprite {
    return new Sprite(sheet, offsetX, offsetY, width, height, false, false, true);
  }
}


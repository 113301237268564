import {action, computed, observable} from "mobx";

/** A small utility type for storing callbacks for when we should resolve or reject a promise. */
type PromiseHandle = {
  resolve: () => void,
  reject: () => void,
}

/**
 * A value that both sides of a connection should agree on.
 * The signaling for how the value is agreed on is left to the person instantiating this value,
 * but this object should keep track of when we've reached consensus on a value.
 *
 * The process for consensus is as follows:
 *   1. One host (we'll call it 'local') sets a value. It signals to the other host ('remote')
 *      that the value has changed. This is done with the set() function.
 *   2. The remote host receives the new value, and acknowledges it. See receiveValue().
 *      It then sends back a message with its acknowledgement.
 *   3. The local host receives the acknowledgement. See commit(). If the object version
 *      has not changed since the process started, the value is assumed to be committed and consensus
 *      reached.
 *
 */
export default class SharedValue<T> {

  /**
   * The last value that we had consensus on, if any.
   */
  @observable _lastConsensusValue: T | null = null;

  /**
   * The latest value we set locally.
   */
  @observable _latestValue: T | null = null;

  /**
   * A monotonically increasing version number for this value. This must match locally
   * and on the remote.
   */
  @observable _latestLocalVersion: number = 0;

  /**
   * The last value that's been successfully acknowledged by the remote side. If this matches the latest
   * local version, then we have consensus.
   * We start without consensus, because the remote of course does not know our value.
   */
  @observable _latestRemoteVersion: number = -1;

  /**
   * A handle on the latest promise. We should either resolve or reject it when
   * we have consensus, or when a new change interrupts our conensus seeking.
   */
  _latestPromiseResolver: PromiseHandle = {resolve: () => {}, reject: () => {}};

  _latestPromise: Promise<T> | null = null;

  /**
   * The method by which we can send a value (and associated version) to the remote side.
   */
  _sendValue: (value: T, version: number) => void;

  /**
   * The method by which we can acknowledge receipt of a value to the sender.
   */
  _sendAck: (version: number) => void;

  /** The constructor */
  constructor(
    sendValue: (value: T, version: number) => void,
    sendAcknowledgement: (version: number) => void,
    initialValue?: T,
  ) {
    this._sendValue = sendValue;
    this._sendAck = sendAcknowledgement;
    if (initialValue !== undefined) {
      this.set(initialValue);
    }
  }

  /**
   * Acknowledge a remote value being received. We set our value, and set the remote
   * version. If the two version align, we have consensus; otherwise, the value is invalid.
   */
  receiveValue = action((value: T, version: number): void => {
    if (version >= this._latestLocalVersion && version >= this._latestRemoteVersion) {
      this._latestValue = value;
    }
    this._latestRemoteVersion = version;
    this._latestLocalVersion = Math.max(version,  this._latestLocalVersion);
    if (this._latestRemoteVersion === this._latestLocalVersion) {
      // We assume consensus as soon as we receive a matching value from the other end
      console.debug('SharedValue: receiveValue() triggered consensus on value=', value, 'at version=', version);
      this._lastConsensusValue = value;
    } else {
      console.debug('SharedValue: receiveValue() did NOT trigger consensus on value=', value, 'at version=', version);
    }
    this._sendAck(version);
  });

  /**
   * Commit our change. That is, recognize that the remote has acknowledged our value, and start using
   * this value as the consensus value if we can.
   */
  commit = action((version: number): void => {
    this._latestRemoteVersion = version;
    this._latestLocalVersion = Math.max(version, this._latestLocalVersion);
    if (this._latestRemoteVersion === this._latestLocalVersion) {
      // The other end has signaled receipt; assume consensus
      console.debug('SharedValue: commit() triggered consensus at version=', version);
      this._lastConsensusValue = this._latestValue;
      this._latestPromiseResolver.resolve();
      this._latestPromiseResolver = {resolve: () => {}, reject: () => {}};
    } else {
      console.debug('SharedValue: commit() did NOT trigger consensus at version=', version);
    }
  });

  /**
   * If true, we have consensus on our value and can therefore safely use it.
   */
  @computed get haveConsensus() {
    return this._latestLocalVersion === this._latestRemoteVersion;
  }

  /**
   * Our latest consensus value, if we have one. Otherwise, null.
   */
  @computed get value(): T | null {
    return this._lastConsensusValue;
  }

  /**
   * Ignoring consensus, get the latest value for this shared value.
   */
  @computed get localValue(): T | null {
    return this._latestValue;
  }

  /**
   * Set our value, and trigger a request to get consensus. This returns a promise
   * that will fire if and only if this value has achieved consensus. The promise will
   * error if consensus is not reached on this value (e.g., it was interrupted by another
   * set call).
   */
  set = action((value: T): Promise<T> => {
    // Short circuit for if our value is already up-to-date
    if (this.haveConsensus && JSON.stringify(value) === JSON.stringify(this._lastConsensusValue)) {
      return Promise.resolve(value);
    }
    // Short circuit if this is a duplicate update
    if (this._latestPromise && JSON.stringify(value) === JSON.stringify(this._latestValue)) {
      return this._latestPromise;
    }

    // Update our local value
    // This should be out of the promise, to avoid instantaneous race conditions where we have to
    // wait for the promise to schedule in order to see the latest value reflected
    this._latestValue = value;
    this._latestLocalVersion += 1;
    // Save the version, in case it changes by the time the promise schedules.
    const version = this._latestLocalVersion;

    // Create our promise
    this._latestPromise = new Promise<T>((resolve, reject) => {
      // Set the new promise
      this._latestPromiseResolver.reject();
      this._latestPromiseResolver = {
        resolve: () => resolve(value),
        reject: () => reject(new Error('A newer value has been set on this SharedValue'))
      };
      // Send a request for change
      this._sendValue(value, version);
    });

    // Return
    return this._latestPromise;
  });

  /**
   * A shared value that's shared with ourselves. This of course defeats the point
   * of having a shared value, but is helpful when we want a local value to conform to the
   * shared value interface. For example, for having our local camera behave the same as our remote
   * camera.
   *
   * @param v The value we're setting for the shared value
   */
  static local = <V> (v: V): SharedValue<V> => {
    const me = new SharedValue<V>(
      (value, version) => {
        me.receiveValue(value, version);
      },
      (version) => {
        me.commit(version);
      }
    );
    me.set(v);
    return me;
  }

}